import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import SummaryList from '../../layout/expense/summary.layout'
import SummaryInfo from '../../layout/expense/summaryinfo.layout'
import { UserContext } from '../../contexts/user.context'
const SummaryPage =() =>{

    const [sidebarOpen, setSidebarOpen] = useState(false)


    const [showSummary, setShowSummary] = useState(true);
    const [showSummaryInfo, setShowSummaryinfo] = useState(false);

    const { company_id } = useContext(UserContext);


    const [allList, setallList] = useState([]);

    useEffect(() => {
      setCompanyid(company_id);
    }, []); 
  
    const [Companyid, setCompanyid] = useState('');


    const [DateEncoded, setDateEncoded] = useState('');
    const [Encoder, setEncoder] = useState('');
    const [Seller, setSeller] = useState('');
    const [Particular, setParticular] = useState('');
    const [TotalAmount, setTotalAmount] = useState('');
    const [Tin, setTin] = useState('');
    const [Classification, setClassification] = useState('');
    const [imageattach, setImageattach] = useState('');
    const [expense_id, setId] = useState(0);
    const [user_id, setuser_id] = useState('');

    const [listItem, setListItem] = useState([]);
    
    const updateListItem = (newList) => {
      setListItem(newList);
    };

    
    const [Vat_Sales, setVat_Sales] = useState('');
    const [Vat_Amount, setVat_Amount] = useState('');
    const [Vat_Zero_Rated, setVat_Zero_Rated] = useState('');
    const [Vat_Exempt, setVat_Exempt] = useState('');

    const GotoSummary = (dateencoded,encoder,seller,amount,tin,classification,imageattach,id,user_id,expense_item,vat_sales,vat_amount,vat_zero_rated,vat_exempt) => {

      setVat_Sales(vat_sales);
      setVat_Amount(vat_amount);
      setVat_Zero_Rated(vat_zero_rated);
      setVat_Exempt(vat_exempt);




      updateListItem(expense_item);
      
      setShowSummary(false);
      setShowSummaryinfo(true);
      setDateEncoded(dateencoded);
      setEncoder(encoder);
      setSeller(seller);
      setTotalAmount(amount);
      setTin(tin);
      setClassification(classification);
      setImageattach(imageattach);
      setId(id);
      setuser_id(user_id);
    }
    
    return(
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto  px-6 pb-4">
            <div className="flex h-42 shrink-0 items-center mt-10">
              <img
                className="h-42 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'}
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'Expense Verified'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
                <div className="space-y-10 divide-y divide-gray-900/10">
                    {showSummary? 
                     <SummaryList GotoSummary={GotoSummary}/>
                    :''}
                    {showSummaryInfo?
                      <SummaryInfo
                      Companyid={Companyid}
                      DateEncoded={DateEncoded}
                      Encoder={Encoder}
                      Seller={Seller}
                      TotalAmount={TotalAmount} 
                      Tin={Tin}
                      Classification={Classification}
                      imageattach={imageattach}
                      expense_id={expense_id}
                      user_id={user_id}
                      listItem={listItem}
                      Vat_Sales={Vat_Sales}
                      Vat_Amount={Vat_Amount}
                      Vat_Zero_Rated={Vat_Zero_Rated}
                      Vat_Exempt={Vat_Exempt}
                      />
                      :''
                    }
                </div>
             </div>
            </div>
          </main>
        </div>
      </div>
  </>);

}
export default SummaryPage;