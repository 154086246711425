import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import { UserContext } from '../../contexts/user.context'
import Swal from 'sweetalert2';
import Customerlist from '../../layout/customer/customerlist.layout'
import CustomerAdd from '../../layout/customer/customeradd.layout'
import CustomerEdit from '../../layout/customer/customeredit.layout'
import { Dialog, Transition } from '@headlessui/react'
import { formatTin } from '../../utils/ValidationField'
const CustomerPage =() =>{

  const { company_id } = useContext(UserContext);

  const [sidebarOpen, setSidebarOpen] = useState(false)


  const [Pagination, setPagination]= useState([]);

  const [showListCustomer, setshowListCustomer] = useState(true);
  const [showAddCustomer, setshowAddCustomer] = useState(false);
  const [showEditCustomer, setshowEditCustomer] = useState(false);


  const defaultFormFields = {
    customer_id:'',
    customer_name: '',
    tin1:'',
    tin2:'',
    email: '',
    address: ''
};
const [formFields,setFormFields ] = useState(defaultFormFields);
const { customer_id,customer_name,tin1,tin2, email,address} = formFields;

  
  const GoAddCustomer = () => {
    setshowListCustomer(false);
    setshowAddCustomer(true);
    setshowEditCustomer(false);
  }

    
  const GoListCustomer = () => {
    setshowListCustomer(true);
    setshowAddCustomer(false);
    setshowEditCustomer(false);
  }

  const GotoEditCustomer= (id,name,address,tin,branch,email,mobile) => {
    setshowEditCustomer(true);
    setshowListCustomer(false);
    setshowAddCustomer(false);

    formFields.customer_id=id;
    formFields.customer_name=name;
    formFields.address=address;
    formFields.tin1=tin;
    formFields.tin2=branch;
    formFields.email=email;

  };

  const [isCompletesubmit, setCompletesubmit] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setCompletesubmit(true);
    const postData = {
      "customer_id": customer_id,
      "name": customer_name,
      "tin": tin1,
      "branch": tin2,

      "company_id": company_id,
      "address": address,
      "email":email
    };

 
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

    await fetch(process.env.REACT_APP_API_URL+'api/setting/customer/update', requestOptions)
    .then( async response => 
      {
          console.log(response);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

          const data = await response.json();
      
          // handleData(data.message);
 
          setCompletesubmit(false);
        }else{
          const data = await response.json();
          console.log(data.message);  
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Update Customer Successfully',
            showConfirmButton: false,
            timer: 1500
            
        })  
        setCompletesubmit(false);
        GoListCustomer();
        // //  navigate('/');
        }
      }
      
    )
    .then(data => {
      // Handle the response
      console.log(data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    });
}
  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name==="tin1"){
  
 
        setFormFields({ ...formFields, [name]: formatTin(value) });
        return; // Exit the function early to prevent setting the value again
    
      };
    if (name==="mobile"){
    
        setFormFields({ ...formFields, [name]: formatMobileNumber(value) });
        return; // Exit the function early to prevent setting the value again
      }
      setFormFields({ ...formFields, [name]: value });
  };
  const formatMobileNumber =(value)=>{

    let numericValue = value.replace(/\D/g, '');

    // Check if the input starts with the country code +63
    if (numericValue.startsWith('639')) {
      // If it does, keep it as it is
      numericValue = `+639 ${numericValue.substring(3)}`;
    } else if (numericValue.length >= 1) {
      // If not, add the country code +63
      numericValue = `+639 ${numericValue}`;
    } else {
      // If the input is empty, clear the input value
      numericValue = '';
    }

    return numericValue;
  }

  return (
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto  px-6 pb-4">
            <div className="flex h-42 shrink-0 items-center   mt-10">
              <img
             className="h-42 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'}
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'Setting'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
              <div className="space-y-10 divide-y divide-gray-900/10">
                    {(showListCustomer)?   <Customerlist GoAddCustomer={GoAddCustomer}  GotoEditCustomer={GotoEditCustomer}/> :''}
                    {(showAddCustomer)?  <CustomerAdd GoListCustomer={GoListCustomer}/> : ''}
                    {(showEditCustomer)?  <CustomerEdit isCompletesubmit={isCompletesubmit} handleSubmit={handleSubmit} handleChange={handleChange} GoListCustomer={GoListCustomer} customer_id={customer_id} customer_name={customer_name} address={address} tin1={tin1} tin2={tin2} email={email}/> : ''}
                    
                </div>
             </div>
            </div>
          </main>
        </div>
      </div>
    </>
   
  )
}
export default CustomerPage;