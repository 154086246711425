const StepFormComplete = (props) => {

    return (
        <>
        <div className="bg-white py-24 sm:py-32 uppercase">
            <div className="mx-auto  lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                <img
                  className="w-32  "
                  src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                  alt="Your Company"
                />
                
                
                <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Terms and Condition</h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                1. <strong>Acceptance of Terms </strong> 
                  By accessing or using the System in any way, you agree to be bound by these Terms. If you do not agree to all the Terms and Conditions of this agreement, then you may not access the System or use any services.
                <br />
                <br />
                2. <strong>Use of the System </strong> 
                You are responsible for maintaining the confidentiality of your account and password.
                You agree to provide accurate and complete information when registering for an account.
                You agree not to use the System for any illegal or unauthorized purpose.
                You agree not to interfere with or disrupt the System or servers or networks connected to the System.
                <br />
                <br />
                3.  <strong>Intellectual Property Rights </strong>
                The System and its original content, features, and functionality are owned by the Company and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                <br />
                <br />
                4. <strong>User Content </strong>
                You retain ownership of any content you submit to the System ("User Content").
                By submitting User Content, you grant the Company a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Content in connection with the System.
                <br />
                <br />
                5. <strong>  Privacy </strong>
                Your use of the System is subject to the Company's Privacy Policy, which can be found [here](link to your privacy policy).
                <br />
                <br />
                6. <strong> Limitation of Liability </strong>
                In no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the System.
                <br />
                <br />
                7. <strong> Termination </strong>
                The Company may terminate or suspend your access to the System immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                <br />
             
                    
                </p>
                </div>
                <fieldset>
                    <legend className="text-sm font-semibold leading-6 text-gray-900"></legend>
                    <div className="mt-6 space-y-6">
                        <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                            <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            checked={props.isChecked}
                            onChange={props.handleCheckboxChange}
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-indigo-600"
                            />
                        </div>
                        <div className="text-sm leading-6">
                       
                            <p className="text-gray-500">do you agree ?</p>
                        </div>
                        </div>
                    
                    
                    </div>
                </fieldset>
                {props.isErroraggreement?
                   <div class="rounded-md bg-red-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                            </svg>
                            </div>
                            <div class="ml-3">
                            <div class="mt-2 text-sm text-red-700">
                                <ul role="list" class="list-disc space-y-1 pl-5 uppercase">
                                {/* <li>Your password must be at least 8 characters</li> */}
                                <li>Error you need to check the agreement</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                  :''
                  }  
                  {props.isEmailInvalid?
                   <div class="rounded-md bg-red-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                            </svg>
                            </div>
                            <div class="ml-3">
                            <div class="mt-2 text-sm text-red-700">
                                <ul role="list" class="list-disc space-y-1 pl-5 uppercase">
                                {/* <li>Your password must be at least 8 characters</li> */}
                                <li>Error email already exist</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                  :''
                  }  
                <div className="mt-6 flex items-center justify-end gap-x-6 ">
                <button
                    onClick={props.BackStepThree}
                    className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Back
                    </button>
                    <button
                    type="submit"
                    className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                    Complete
                    </button>

                 
                </div>
            </div>
         </div>
        
        </>
       
        )
    }
    export default StepFormComplete;