import { useEffect,useState,useContext } from 'react'
import { ExclamationTriangleIcon,PencilSquareIcon,Cog6ToothIcon } from '@heroicons/react/20/solid'
import PaginatedTable from '../../components/pagination/paginatedtable.component'
import { UserContext } from '../../contexts/user.context'
import {
  TagIcon,
  BuildingStorefrontIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import { SentenceCaseText } from '../../utils/ValidationField';
import SettingNavigation from '../setting/SettingNavigation.layout'
const SupplierList =(props) =>{


    const secondaryNavigation = [
      { name: 'Classification Expense', href: '/setting/classification', icon: TagIcon, current: false },
        { name: 'Classification Sales', href: '/setting/classification/sales', icon: TagIcon, current: false },
      { name: 'User', href: '/setting/user', icon: UserIcon, current: false },
      { name: 'Supplier', href: '/setting/supplier', icon: BuildingStorefrontIcon, current: true },
    ]
    
    const pages = [
        { name: 'Supplier', href: '#', current: false },
      ]
    
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      
      

    const [Supplierlist, setSupplierlist] = useState([]);
    const { company_id } = useContext(UserContext);


    
    const [Searchdata, setInputValue] = useState('');

    const [Pagination, setPagination]= useState([]);


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };

    useEffect( () => {
        setLoad(false);

        LoadSupplierist();
        setLoad(true);
       
    }, []); 

    const handlePageChangeParent = (newPage) => {
      // LoadPendingList(newPage);
      setSupplierlist([]);
      LoadSupplierist(newPage)

      console.log(newPage);
    };


    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        LoadSupplierist();
      };
 
  const LoadSupplierist = async (page = 1) => {
        console.log(company_id);
      const postData = {
        "company_id": company_id,
        "searchdata":Searchdata,
        "page":page
      };
  
      console.log(postData);
      const requestOptions = {
        method: 'POST',
        headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
     await fetch(process.env.REACT_APP_API_URL+'api/setting/supplier/list', requestOptions)
        .then( async response => 
          {
              console.log(response.status);
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
              const data = await response.json();
          
              // handleData(data.message);
              setNodata(false);
            }else{
              const data = await response.json();
              setSupplierlist(data.data); 
               setPagination(data.pagination)
            //  navigate('/');
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
          console.log(data);
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
    } 

    const [isLoad, setLoad] = useState(false);
    const [isNodata, setNodata] = useState(false);

  
    return (
        <div>
          
          <nav className="flex mt-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                <div>
                    <a href="#" className="text-gray-400 hover:text-gray-500">
                    <Cog6ToothIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
            {pages.map((page) => (
            <li key={page.name}>
                <div className="flex items-center">
                <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                >
                    {page.name}
                </a>
                </div>
            </li>
            ))}
        </ol>
        </nav>
        <div className="flex gap-4">
        <div className="w-3/12">
                <aside className="flex  border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                      <SettingNavigation pageIndex={3}/>
                </aside>

             </div>
             <div className="w-9/12	">
                  <main>
                      <div className="sm:flex sm:items-center mt-4">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Suppliers</h1>
                     
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={props.GotoAddSupplier}
                          className=" block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Add Supplier
                        </button>
                      </div>
                    </div>

                              
                  <form onSubmit={handleSubmitSearch}>
                    <div class="relative mt-4 2xl:w-1/3">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                              </svg>
                          </div>
                          <input type="search"
                          id="default-search" 
                          class=" block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                          placeholder="Search Supplier..." 
                          value={Searchdata}
                          onChange={handleInputChange}
                          />
                          <button type="submit"     class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                      </div>
                  </form>

                    <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root  rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Supplier Name
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Address
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    tin
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    email
                                  </th>
                                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Edit</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {Supplierlist.map((supplier) => (
                                    <tr key={supplier.id}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        
                                          <SentenceCaseText text={supplier.supplier_name.toLowerCase()} />
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><SentenceCaseText text={supplier.address.toLowerCase()} /></td>
   
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{supplier.tin}{supplier.branch}</td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{supplier.email}</td>
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <button   
                                        onClick={() =>props.GotoEditSupplier(
                                          supplier.id,
                                          supplier.supplier_name,
                                          supplier.address,
                                          supplier.tin,
                                          supplier.branch,
                                          supplier.email,
                                          supplier.mobile
                                        )}   className="rounded-full bg-gray-500 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                                        <span class="sr-only">{supplier.id}</span></button>
                                    </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {Supplierlist.length===0 && isNodata?
                            <div className="rounded-md bg-yellow-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                    
                                    <div className="mt-2 text-sm text-yellow-700 ">
                                        <p>
                                                Data Not Found
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''}
                            <div className="container mx-auto p-4">
                              <PaginatedTable data={Supplierlist} pagination={Pagination} handlePageChangeParent={handlePageChangeParent}/>
                            </div>
                      

                          </div>
                        </div>
                      </div>
                    </div>
                      
                  </main>
             </div>
          </div>
        
    

      </div>);

}
export default SupplierList;