
import { ExclamationTriangleIcon,PencilSquareIcon,Cog6ToothIcon,LockClosedIcon } from '@heroicons/react/20/solid'
import PaginatedTable from '../../components/pagination/paginatedtable.component'
import { UserContext } from '../../contexts/user.context'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState,useEffect,useContext } from 'react'

import {
  TagIcon,
  BuildingStorefrontIcon,
  UserIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import SettingNavigation from '../setting/SettingNavigation.layout'
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
const UserList =(props) =>{

  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)


    const secondaryNavigation = [
      { name: 'Classification Expense', href: '/setting/classification', icon: TagIcon, current: false },
      { name: 'Classification Sales', href: '/setting/classification/sales', icon: TagIcon, current: false },
      { name: 'User', href: '/setting/user', icon: UserIcon, current: true },
      { name: 'Supplier', href: '/setting/supplier', icon: BuildingStorefrontIcon, current: false },
    ]
    
    const pages = [
        { name: 'User', href: '#', current: false },
      ]
    
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      
      

    const [userList, setUserList] = useState([]);
    const { company_id,user_id } = useContext(UserContext);
    const [isCompletesubmit, setCompletesubmit] = useState(false);


    
    const [Searchdata, setInputValue] = useState('');

    const [Pagination, setPagination]= useState([]);

        
    const [UserID, setUserID] = useState('');


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };

    useEffect( () => {
        setLoad(false);

        LoadUserList();
        setLoad(true);
       
    }, []); 

    const handlePageChangeParent = (newPage) => {
      // LoadPendingList(newPage);
      setUserList([]);
      LoadUserList(newPage)

      console.log(newPage);
    };


    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        LoadUserList();
      };
 
  const LoadUserList = async (page = 1) => {
        console.log(company_id);
      const postData = {
        "company_id": company_id,
        "searchdata":Searchdata,
        "page":page
      };
  
      console.log(postData);
      const requestOptions = {
        method: 'POST',
        headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
     await fetch(process.env.REACT_APP_API_URL+'api/user/list', requestOptions)
        .then( async response => 
          {
              console.log(response.status);
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
              const data = await response.json();
          
              // handleData(data.message);
              setNodata(false);
            }else{
              const data = await response.json();
               setUserList(data.data); 
               setPagination(data.pagination)
            //  navigate('/');
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
          console.log(data);
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
    } 

    const [isLoad, setLoad] = useState(false);
    const [isNodata, setNodata] = useState(false);

    
    const ResetPassword =  (user_id) => {

      setOpen(true);
        
      setUserID(user_id);
     

    }
  
    const defaultFormFields = {

      newpassword:'',
      currentpassword:''

    };
    const [formFields,setFormFields ] = useState(defaultFormFields);
    const { confirmpassword,newpassword } = formFields;

    const handleChange = (event) => {
      const { name, value } = event.target;


  
      const formattedValue =  value;
      setFormFields({ ...formFields, [name]: formattedValue });
    };
    const handleSubmit = async (event) => {
      event.preventDefault();
      

      if(newpassword != confirmpassword ){

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Password not match',
          
          })

          return;
          
      }

      const postData = {
        "new_password": newpassword,
        "encoder_id": user_id,
        "company_id":company_id,
        "user_id":UserID
      };

      console.log(postData);

      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        
        body: JSON.stringify(postData)
      };

      await fetch(process.env.REACT_APP_API_URL+'api/admin/change/password', requestOptions)
      .then( async response => 
        {
            console.log(response);
          // Check the status code
          if (response.status === 422) {
            // API call succeeded

            const data = await response.json();


            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Invalid Current Password',
              
              })

              return;
          
          }else{
            const data = await response.json();
            console.log(data.message);  

            localStorage.setItem('first_login', 0);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Update Password Successfully',
              showConfirmButton: false,
              timer: 1500
          })  
          window.location.reload();
          }
        }
        
      )
      .then(data => {
        // Handle the response
        console.log(data);
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });

    }
    return (
        <div >
          <div className={isCompletesubmit ? "opacity-20" : ""}>
          <nav className="flex mt-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                <div>
                    <a href="#" className="text-gray-400 hover:text-gray-500">
                    <Cog6ToothIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
            {pages.map((page) => (
            <li key={page.name}>
                <div className="flex items-center">
                <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                >
                    {page.name}
                </a>
                </div>
            </li>
            ))}
        </ol>
        </nav>

        <div className="flex gap-4">
        <div className="w-3/12">
                <aside className="flex  border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                     <SettingNavigation pageIndex={2}/>
                    </aside>

             </div>
             <div className="w-9/12	">
                            
                  <main>
                      <div className="sm:flex sm:items-center mt-4">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
                      
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={props.GotoAddUser}
                          className=" block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Add User
                        </button>
                      </div>
                    </div>

                              
                  <form onSubmit={handleSubmitSearch}>
                  <div class="relative mt-4 2xl:w-1/3">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                              </svg>
                          </div>
                          <input type="search"
                          id="default-search" 
                          class="uppercase block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                          placeholder="Search user..." 
                          value={Searchdata}
                          onChange={handleInputChange}
                          />
                          <button type="submit"     class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                      </div>
                  </form>

                    <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root  rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Name
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Username
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Contact
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Role
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Status
                                  </th>
                                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Edit</span>
                                  </th>
                                  
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {userList.map((user) => (
                                  <tr key={user.email}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {user.first_name} {user.middle_name} {user.last_name} {user.suffix}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.email}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.mobile}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.role}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {
                                        (user.status==1)? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        Active
                                      </span> :
                                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                        Inactive
                                        </span>
                                      }
                                    
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                          <div className='flex gap-4'>
                                              <div>
                                                  <button   
                                                    onClick={() =>ResetPassword(user.user_id)}   className="rounded-full bg-gray-500 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        <LockClosedIcon className="h-5 w-5" aria-hidden="true" />
                                                    <span class="sr-only">{user.id}</span></button>
                                              </div>
                                              <div>
                                                  <button   
                                                    onClick={() =>props.GotoEditUser(
                                                      user.email.toLowerCase(),
                                                      user.first_name,
                                                      user.middle_name,
                                                      user.last_name,
                                                      user.suffix,
                                                      user.mobile,
                                                      user.role,
                                                      user.user_id,
                                                      user.privelege
                                                    )}   className="rounded-full bg-gray-500 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                                                    <span class="sr-only">{user.id}</span></button>
                                              </div>
                                          </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {userList.length===0 && isNodata?
                            <div className="rounded-md bg-yellow-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                    
                                    <div className="mt-2 text-sm text-yellow-700 ">
                                        <p>
                                                Data Not Found
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''}
                            <div className="container mx-auto p-4">
                              <PaginatedTable data={userList} pagination={Pagination} handlePageChangeParent={handlePageChangeParent}/>
                            </div>
                      

                          </div>
                        </div>
                      </div>
                    </div>
                      
                  </main>
             </div>
          </div>
        
          </div>
          {isCompletesubmit?
                  <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" >
                      <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                      <span class="sr-only">Loading...</span>
                  </div>   
              :''}


<Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                <form onSubmit={handleSubmit}>
                                         <h1 class="text-base font-semibold leading-6 text-gray-900 text-left mb-6" >Change Password</h1>
                                        <div className="mt-2">
                                      

                                        <div className="sm:col-span-3 ">
                                            <div className="relative mb-6" data-te-input-wrapper-init>
                                            <label for="currentpassword" className="block text-sm font-medium leading-6 text-gray-900">New Password  <span className='text-red-500'>*</span></label>
                                                  <div className="mt-2 relative">
                                                    <input type='password'
                                                    name="newpassword" 
                                                    required
                                                    id="newpassword" 
                                                    onChange={handleChange}
                                                    autoComplete="currentpassword"  className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                  
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                           
  
                                       
                                        <div className="mt-2">
                                      

                                        <div className="sm:col-span-3 ">
                                            <div className="relative mb-6" data-te-input-wrapper-init>
                                            <label for="currentpassword" className="block text-sm font-medium leading-6 text-gray-900">Confirm Password  <span className='text-red-500'>*</span></label>
                                                  <div className="mt-2 relative">
                                                    <input  type='password'
                                                    name="confirmpassword" 
                                                    id="confirmpassword" 
                                                    required
                                                    onChange={handleChange}
                                                    autoComplete="currentpassword" className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                  
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 py-4 uppercase">
                                        <button
                                        type="submit"
                                        
                                        className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                        Update
                                        </button>
                                    </div>
                                    </form>
                                </div>
                                
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>

        
      </div>);




}
export default UserList;