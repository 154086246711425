import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import SupplierList from '../../layout/supplier/supplierlist.layout'
import SupllierAdd from '../../layout/supplier/supplieradd.layout'
import SupllierEdit from '../../layout/supplier/supplieredit.layout'
import Swal from 'sweetalert2';
// import './sweetbutton.css'; //
// import Swal from 'sweetalert2';
import { UserContext } from '../../contexts/user.context'
import { useNavigate } from 'react-router-dom'
import { formatTin } from '../../utils/ValidationField';

const SupplierSettingPage =() =>{

  
  const navigate = useNavigate();


    const [sidebarOpen, setSidebarOpen] = useState(false)

    const { company_id } = useContext(UserContext);
    const { user_id } = useContext(UserContext);
 
    const [selectedFile, setSelectedFile] = useState(null);

    const [showListSupllier, setshowListSupllier] = useState(true);
    const [showAddSupplier, setshowAddSupplier] = useState(false);
    const [showupdateSupplier, setshowupdateSupplier] = useState(false);

    const [isCompletesubmit, setCompletesubmit] = useState(false);

    
  const defaultFormFields = {
    supplier_id:'',
    supplier_name: '',
    tin1:'',
    tin2:'',
    email: '',
    mobile: '+639',
    address: '',

  };
  const [formFields,setFormFields ] = useState(defaultFormFields);
  const { supplier_name,tin1,tin2,email,mobile,address,supplier_id } = formFields;
    
  const resetForm = () => {
    setFormFields(defaultFormFields);
  };
  const GotoAddSupplier = () => {
    setshowupdateSupplier(false);
    setshowListSupllier(false);
    setshowAddSupplier(true);
  }

  const GotoEditSupplier= (id,supplier_name,address,tin,branch,email,mobile) => {
    formFields.supplier_id=id;
    formFields.supplier_name=supplier_name;
    formFields.address=address;
    formFields.tin1=tin;
    formFields.tin2=branch;
    formFields.email=email;
    formFields.mobile=mobile;
    setshowupdateSupplier(true);
    setshowListSupllier(false);
    setshowAddSupplier(false);


  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name==="tin1"){
  
 
        setFormFields({ ...formFields, [name]: formatTin(value) });
        return; // Exit the function early to prevent setting the value again
    
      };
    if (name==="mobile"){
    
        setFormFields({ ...formFields, [name]: formatMobileNumber(value) });
        return; // Exit the function early to prevent setting the value again
      }
      setFormFields({ ...formFields, [name]: value });
  };

  

  const formatMobileNumber =(value)=>{

    let numericValue = value.replace(/\D/g, '');

    // Check if the input starts with the country code +63
    if (numericValue.startsWith('639')) {
      // If it does, keep it as it is
      numericValue = `+639 ${numericValue.substring(3)}`;
    } else if (numericValue.length >= 1) {
      // If not, add the country code +63
      numericValue = `+639 ${numericValue}`;
    } else {
      // If the input is empty, clear the input value
      numericValue = '';
    }

    return numericValue;
  }



  
  const handleSubmit = async (event) => {
    event.preventDefault();

    setCompletesubmit(true);
    const postData = {
      "supplier_name": supplier_name,
      "tin": tin1,
      "branch": tin2,
      "mobile": mobile,
      "company_id": company_id,
      "address": address,
      "email":email
    };

 
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

    await fetch(process.env.REACT_APP_API_URL+'api/setting/supplier/create', requestOptions)
    .then( async response => 
      {
          console.log(response.status);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

          const data = await response.json();
      
          // handleData(data.message);
 
          setCompletesubmit(false);
        }else{
          const data = await response.json();
          console.log(data.message);  
          setCompletesubmit(false);
          setshowListSupllier(true);
          setshowAddSupplier(false);
          resetForm();
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Successfully Supplier Created',
            showConfirmButton: false,
            timer: 1500
        })  
        // //  navigate('/');
        }
      }
      
    )
    .then(data => {
      // Handle the response
      console.log(data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    });
   
  }; 

  
  const handleSubmitUpdate = async (event) => {
    event.preventDefault();

    console.log(supplier_id);
    setCompletesubmit(true);
    const postData = {
      "supplier_id": supplier_id,
      "supplier_name": supplier_name,
      "tin": tin1,
      "branch": tin2,
      "mobile": mobile,
      "company_id": company_id,
      "address": address,
      "email":email
    };

    console.log(email);
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

    await fetch(process.env.REACT_APP_API_URL+'api/setting/supplier/update', requestOptions)
    .then( async response => 
      {
          console.log(response.status);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

          const data = await response.json();
      
          // handleData(data.message);
 
          setCompletesubmit(false);
        }else{
          const data = await response.json();
          console.log(data.message);  
          setCompletesubmit(false); 
          setshowAddSupplier(false);
          resetForm();
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Successfully Supplier Updated',
            showConfirmButton: false,
            timer: 1500
        })  
        setshowupdateSupplier(false);
        setshowListSupllier(true);
        }
      }
      
    )
    .then(data => {
      // Handle the response
      console.log(data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    });
   
  }; 
    return(
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 border border-gray-100">
            <div className="flex h-42 shrink-0 items-center   mt-10">
              <img
                className="h-42 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                alt="Your Company"
              />
            </div>
           <SidebarNavigation  activepage={'Setting'}/>:'' 
           
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
                <div className="space-y-10 divide-y divide-gray-900/10">
                    {(showListSupllier)?  <SupplierList   GotoAddSupplier={GotoAddSupplier} GotoEditSupplier={GotoEditSupplier}/> :''}
                    {(showAddSupplier)?  <SupllierAdd supplier_name={supplier_name} handleSubmit={handleSubmit}
                    tin1={tin1} tin2={tin2} email={email}  address={address} mobile={mobile} handleChange={handleChange } /> :''}
                    {(showupdateSupplier)? <SupllierEdit supplier_name={supplier_name} tin1={tin1} tin2={tin2} email={email} mobile={mobile} address={address} handleChange={handleChange } handleSubmitUpdate={handleSubmitUpdate}/>:''}
                </div>
             </div>
            </div>
          </main>
        </div>
      </div>
  </>);

}
export default SupplierSettingPage;