import { useState,useEffect,useContext,Fragment,useRef } from 'react'


import { PencilSquareIcon,ExclamationTriangleIcon,Cog6ToothIcon, UserIcon, BuildingStorefrontIcon } from '@heroicons/react/20/solid'
import { SentenceCaseText } from '../../utils/ValidationField';
import { UserContext } from '../../contexts/user.context'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/24/outline'
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import {
  TagIcon
} from '@heroicons/react/24/outline'
import SettingNavigation from './SettingNavigation.layout';


const SettingClassification =() =>{

      const secondaryNavigation = [
        { name: 'Classification Expense', href:'/setting/classification/', icon: TagIcon, current: true },
        { name: 'Classification Sales', href: '/setting/classification/sales', icon: TagIcon, current: false },
        { name: 'User', href: '/setting/user', icon: UserIcon, current: false },
        { name: 'Supplier', href: '/setting/supplier', icon: BuildingStorefrontIcon, current: false },
      ]
      
      const pages = [
          { name: 'Account Expense', href: '#', current: false },
        ]
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      
      
      
      
     const { company_id } = useContext(UserContext);

    
   
    const [Searchdata, setInputValue] = useState('');
    const [isNodata, setNodata] = useState(false);

    const [allList, setallList] = useState([]);

    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)


    const [Class_id, setClass_id] = useState('');



    const [TypeFunction, setTypeFunction] = useState('Create Classification');



    useEffect( () => {
        // setLoad(false);
    
        LoadAllList();
       
    }, []); 

    const UpdateClassification=(classification,class_id)=>{
        setOpen(true)

        setClassificationDirectly(classification)


        setClass_id(class_id);

     
        
        setTypeFunction('Update Account');
        
    }

    const AddClassification=()=>{
        setOpen(true)

        setClassificationDirectly('')
        setTypeFunction('Create Account');
    }
    
    const setClassificationDirectly = (newClassification) => {
        setFormFields({ ...formFields, classification: newClassification });
      };
    

    const LoadAllList = async () => {



          const postData = {
            "search":Searchdata,
            "company_id": company_id,
            "type": 'expense',
        
          };
         
          
          
          const requestOptions = {
            method: 'POST',
            headers: 
            { 'Content-Type': 'application/json',
            'Accept': 'application/json', 
             'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
            body: JSON.stringify(postData)
          };
      
       await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/list', requestOptions)
          .then( async response => 
            {
               console.log(response);
              // Check the status code
              if (response.status === 422) {
                // API call succeeded
    
               // const data = await response.json();
             
                // handleData(data.message);
                 setNodata(false);
                 
              }else{
                const data = await response.json();
                console.log(data.data);
                setallList(data.data); 
  
             
                
              }
            }
            
          )
          .then(data => {
            // Handle the response
         
        
            setLoad(true);
          })
          .catch(error => {
            // Handle any errors
         
          });
          setLoad(true);
      } 
  
  
    const [isLoad, setLoad] = useState(true);


    const defaultFormFields = {

        classification: '',
    
  
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);

 
    const { classification } = formFields;


    const resetFormFields = () => {
        setFormFields(defaultFormFields);
        //('');
    };
  
    
    const handleChange = (event) => {
        const { name, value } = event.target;
     
        const formattedValue =  value;
        setFormFields({ ...formFields, [name]: formattedValue });
      };


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
  


    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        LoadAllList();

        console.log('xxx');
      };



      const handleSubmit = async (event) => {
        event.preventDefault();
    
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        
              
        if(classification ==''){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Complete the data',
            
            })
            return;
        }
       
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "Do you want to save",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          reverseButtons: true
        }).then(async (result) => {
          if (result.isConfirmed) {


            if(TypeFunction=='Create Account'){
                  
            // setCompletesubmit(true);
            const postData = {
                "classification": classification,
                "company_id": company_id,
                "type": 'expense',
              };
      
        
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/create', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                

                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                  //   setCompletesubmit(false);
                    
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Create Account Successfully',
                        showConfirmButton: false,
                        timer: 1500
                    })  
                 
                 
                      LoadAllList();
                      resetFormFields();
                      setOpen(false);
                      
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
      
            }else{

                console.log(Class_id);

                const postData = {
                    "classification": classification,
                    "company_id": company_id,
                    "id": Class_id,
                    "type": 'expense',
       
                  };
          
            
                  const requestOptions = {
                    method: 'POST',
                    headers: 
                    { 'Content-Type': 'application/json',
                    'Accept': 'application/json', 
                     'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                    body: JSON.stringify(postData)
                  };
          
                  await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/update', requestOptions)
                  .then( async response => 
                    {
                        console.log(response);
                      // Check the status code
                      if (response.status === 422) {
                        // API call succeeded
          
                        const data = await response.json();
                    
                      //   setCompletesubmit(false);
          
                      
                      }else{
                        const data = await response.json();
                        console.log(data.message);  
                      //   setCompletesubmit(false);
                        
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Update Account Successfully',
                            showConfirmButton: false,
                            timer: 1500
                        })  
                     
                     
                          LoadAllList();
                          resetFormFields();
                          setOpen(false);
                          
                        
                      }
                    }
                    
                  )
                  .then(data => {
                    // Handle the response
                    console.log(data);
                  })
                  .catch(error => {
                    // Handle any errors
                    console.error(error);
                  });
            }
        
     
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
    
          }
        })
       
      }; 
    

    return (
        <div>
     
        <nav className="flex mt-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                <div>
                    <a href="#" className="text-gray-400 hover:text-gray-500">
                    <Cog6ToothIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
            {pages.map((page) => (
            <li key={page.name}>
                <div className="flex items-center">
                <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                >
                    {page.name}
                </a>
                </div>
            </li>
            ))}
        </ol>
        </nav>

        <div className="flex gap-4">
            <div className="w-3/12">
             <aside className="flex  border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                <SettingNavigation pageIndex={0}/>
                </aside>
             </div>
             <div className="w-9/12	">
                <main className="px-4 py-2 sm:px-6 lg:flex-auto lg:px-0 ">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                         
                        <form onSubmit={handleSubmitSearch}>
                            <div class="relative mt-4 2xl:w-1/3">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                    </svg>
                                </div>
                                <input type="search"
                                id="default-search" 
                                class=" block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                placeholder="Search class..." 
                                value={Searchdata}
                                onChange={handleInputChange}
                                />
                                <button type="submit"   class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                            </div>
                        </form>
                 
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => AddClassification()}
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                        >
                            ADD
                        </button>
                        </div>
                    </div>
                    <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root  rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>

                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Account
                                    </th>
                            
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {allList.map((Classification) => (
                                <tr key={Classification.id}>
                                    <td className="whitespace-nowrap py-1 text-sm font-medium text-gray-900 sm:pl-6 ">
                                    {Classification.class}
                                    </td>
                                
                                    <td className="relative whitespace-nowrap py-1 text-right text-sm font-medium sm:pr-6">
                                
                                    <button
                                        type="button"
                                        onClick={() => UpdateClassification(Classification.class,Classification.id)}
                                        className="rounded-full bg-gray-500 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                        <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                                    </button> 
                                    </td>
                                </tr>
                                ))}
                                </tbody>
                                </table>
                  
                                {allList.length==0 && isNodata?
                                <div className="rounded-md bg-yellow-50 p-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                        
                                        <div className="mt-2 text-sm text-yellow-700 ">
                                            <p>
                                                    Data Not Found
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''}

                            </div>
                            </div>
                        </div>
                        </div>
              
       
                </main>
             </div>


       
             <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                         <h1 class="text-base font-semibold leading-6 text-gray-900 text-left " >{TypeFunction}</h1>
                                        <div className="mt-2">
                                      

                                        <div className="grid grid-cols-1">
                                          
                                            <form  onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 ">
                                                <div className="px-4 py-6 sm:p-8">
                                                    <div className="grid grid-cols-1 ">
                                                
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left ">
                                                                Account
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                type="text"
                                                                name="classification"
                                                                id="classification"
                                                                autoComplete="classification"
                                                                value={classification}
                                                                required
                                                                onChange={handleChange}
                                                            
                                                               
                                                                
                                                                className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>

                                                
                                                        </div>
                                                    </div>
                                            
                                                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 ">
                                                    <button
                                                    type="submit"
                                                    className=" rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                    Save
                                                    </button>
                                                </div>

                                            </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>
           
        </div>
      </div>);

}
export default SettingClassification;