import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import FAExepense from '../../layout/expenseverification/faexpense.layout'
import FAExpenseSummaryCreate from '../../layout/expenseverification/faexpensesummarycreate.layout'
import { UserContext } from '../../contexts/user.context'
import React from 'react';
import Swal from 'sweetalert2';

const FAExpensePage =() =>{

    const [sidebarOpen, setSidebarOpen] = useState(false)

    const [showSummaryCreate, setshowSummaryCreate] = useState(false);
    const [showListPending, setshowListPending] = useState(true);

   


    const [Encoder, setEncoder] = useState('');
    const [DateEncoded, setDateEncoded] = useState('');
    const [Seller, setSeller] = useState('');
    const [Particular, setParticular] = useState('');
    const [Tin, setTin] = useState('');
    const [Classification, setClassification] = useState('');
    const [imageattach, setImageattach] = useState('');
    const [expense_id, setId] = useState(0);
    const [_user_id, setuser_id] = useState('');

    const [Vat_Sales, setVat_Sales] = useState('');
    const [Vat_Amount, setVat_Amount] = useState('');
    const [Vat_Zero_Rated, setVat_Zero_Rated] = useState('');
    const [Vat_Exempt, setVat_Exempt] = useState('');

    const { user_id } = useContext(UserContext);
    
    
    const [TotalAmount, setTotalAmount] = useState([]);

    const [listItem, setListItem] = useState([]);
    
    const updateListItem = (newList) => {
      setListItem(newList);
    };
    const GotoCreateSummary = (encoder,seller,tin,classification,imageattach,id,user_id,dateencoded,totalamount,expense_item,vat_sales,vat_amount,vat_zero_rated,vat_exempt) => {

      setVat_Sales(vat_sales);
      setVat_Amount(vat_amount);
      setVat_Zero_Rated(vat_zero_rated);
      setVat_Exempt(vat_exempt);


      setDateEncoded(dateencoded);
      updateListItem(expense_item);
      setshowSummaryCreate(true);
      setshowListPending(false);
      setEncoder(encoder);
      setSeller(seller);
      setTin(tin);
      setClassification(classification);
      setImageattach(imageattach);
      setId(id);
      setuser_id(user_id);
      setTotalAmount(totalamount);


      console.log(totalamount);
    }
  


    
    const backLoadPendingList = async () => {
      setshowSummaryCreate(false);   
      setshowListPending(true);
    };  
    return(
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto  px-6 pb-4">
          <div className="flex h-42 shrink-0 items-center   mt-10">
              <img
                className="h-42 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'}
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'Expense Verification'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
              <div className="space-y-10 divide-y divide-gray-900/10">
                    {showListPending? 
                     <FAExepense GotoCreateSummary={GotoCreateSummary}   imageattach={imageattach}  />
                    :''}
                   
                    {/* {showSummaryCreate? 
                    <FAExpenseSummaryCreate  
                    backLoadPendingList={backLoadPendingList}
                      Encoder={Encoder}
                      Seller={Seller}
                      Particular={Particular} 
                      Tin={Tin}
                      Classification={Classification}
                      imageattach={imageattach}
                      expense_id={expense_id}
                      user_id={_user_id}
                      DateEncoded={DateEncoded}
                      TotalAmount={TotalAmount}
                      listItem={listItem}
                      Vat_Sales={Vat_Sales}
                      Vat_Amount={Vat_Amount}
                      Vat_Zero_Rated={Vat_Zero_Rated}
                      Vat_Exempt={Vat_Exempt}
                      />
                    :''} */}
                </div>
             </div>
            </div>
          </main>
        </div>
      </div>
  </>);

}
export default FAExpensePage;