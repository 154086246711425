

import { CheckIcon } from '@heroicons/react/24/solid'
import {  useState,useEffect } from 'react'
import StepFormOne from '../../components/steps/stepformone.component';
import StepFormTwo from '../../components/steps/stepformtwo.component';
import StepFormComplete from '../../components/steps/stemformcomplete.component';
import StepTaxPayer from '../../components/steps/steptaxpayer.component';
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import { formatTinFull } from '../../utils/ValidationField';
const StepOneReg =() =>{

  const [steps,setSteps] = useState([
    { id: '01', name: 'Company Information', href: '#', status: 'current' },
    { id: '02', name: 'TaxPayer Profile', href: '#', status: 'upcoming' },
    { id: '03', name: 'Account', href: '#', status: 'upcoming' },
    { id: '04', name: 'Complete your setup', href: '#', status: 'upcoming' },
  ]);
  

  const [ListProvince, setListProvince] = useState([]);
  const [ListCity, setListCity] = useState([]);
  const [ListBarangay, setListBarangay] = useState([]);


  const [SelectedVAT, setSelectedSelectedVAT] = useState('vt');

  const handleVATChange = (event) => {

    console.log(event.target.value);
    setSelectedSelectedVAT(event.target.value);
  };
  

  useEffect(() => {
    LoadProvince();
    
  }, []); 



  const [Taxtypes, setTaxTypes] = useState([
    { id: 1, description: 'Income Tax', checked: true },
    { id: 2, description: 'Percentage Tax (PT)', checked: true },
    { id: 3, description: 'Value added tax (VT)', checked: false },
    { id: 4, description: 'Expanded Withholding (WE)', checked: true },
    { id: 5, description: 'Withholding on compensation (WC)', checked: true },
    { id: 6, description: 'Documentary Stamp Tax (DS)', checked: false },
    { id: 7, description: 'Final withholding tax (WF)', checked: false },
  ]);


  const [selectedAgentOption, setselectedAgentOption ] = useState('1'); // State to store the selected option

  const TopAgentRadio = [
    { id: 1, title: 'Yes' },
    { id: 0, title: 'No' },
  ];

  const handleRadioAgentChange = (event) => {
    setselectedAgentOption(event.target.value); // Update the selected option state


  };


  const handleCheckboxChangeTax = (id) => {
    const updatedTaxTypes = Taxtypes.map((tax) =>
      tax.id === id ? { ...tax, checked: !tax.checked } : tax
    );
    setTaxTypes(updatedTaxTypes);
    console.log(Taxtypes);
  };

  const navigate = useNavigate();

  const defaultFormFields = {
    company: '',
    description:'',
    email: '',
    mobile: '+639',
    password: '',
    confirmpassword: '',
    landline:'',
    province:'',
    city:'',
    barangay:'',
    street:'',
    businessType: '',
    trade_name:'',
    tin:'',
    psic:'',
    reg_address: '',
    line_business:'',
    is_top_withhold_agent:selectedAgentOption,
    tax_types:Taxtypes
  };
  const [formFields,setFormFields ] = useState(defaultFormFields);
  const { company,description, email,mobile,password,confirmpassword,landline,province,city,barangay,street,businessType,trade_name,tin,psic,reg_address,line_business,top_withold_agent} = formFields;


  const completeStepAndSetCurrent = (completedStepId, currentStepId) => {

  const updatedSteps = steps.map(step => {
    if (step.id === completedStepId) {
      return { ...step, status: 'complete' };
    } else if (step.id === currentStepId) {
      return { ...step, status: 'current' };
    }
    return step;

  });


  setSteps(updatedSteps);
  };


  const handleSubmitStepOne = (event) => {
    event.preventDefault();


    completeStepAndSetCurrent('01', '02');
    setStepOneVisible(false);
    setisStepTaxPayerVisibleVisible(true);

    
  };

  const [isErrorPasswordText, setErrorPasswordText] = useState('');


  const handleSubmitStepTwo = (event) => {
    event.preventDefault();

    if(password !==confirmpassword){
      setErrorPasswordVisible(true);
      setErrorPasswordText('Invalid confirm password is not the same');
      return;
    }else{
      setErrorPasswordVisible(false);
    }
    

    if(checkPasswordStrength(password)!=='Strong'){
      setErrorPasswordVisible(true);
      setErrorPasswordText('Invalid your password was not strong');
    }else{

    completeStepAndSetCurrent('03', '04');
    setStepOneVisible(false);
    setStepTwoVisible(false);
    setStepCompleteVisible(true);
    }
  
    
  };


  const handleSubmitTaxInfo = (event) => {
    event.preventDefault();

   
    completeStepAndSetCurrent('02', '03');
    setStepOneVisible(false);
    setisStepTaxPayerVisibleVisible(false);
    setStepTwoVisible(true);
    }
  

    const BackTaxPayer = (event) => {
      event.preventDefault();
      completeStepAndSetCurrent('01', '02');
      setStepOneVisible(true);
      setisStepTaxPayerVisibleVisible(false);
      setStepTwoVisible(false);
      setStepCompleteVisible(false);
    }; 
    
  
  const BackStepTwo = (event) => {
    event.preventDefault();
    completeStepAndSetCurrent('01', '02');
    setStepOneVisible(false);
    setisStepTaxPayerVisibleVisible(true);
    setStepTwoVisible(false);
    setStepCompleteVisible(false);
  }; 


  //



  const BackStepThree = (event) => {
    event.preventDefault();
    completeStepAndSetCurrent('02', '03');
    setStepOneVisible(false);
    setStepTwoVisible(true);
    setStepCompleteVisible(false);

  }; 
  const handleSubmitComplete = async (event) => {
    event.preventDefault();
    setErroraggreement(false);
    if(isChecked!==true){
      setErroraggreement(true);
      return;
    }
    setCompletesubmit(true);

    

    const postData = {
      "company": company,
      "description": businessType,
      "email": email,
      "mobile": mobile,
      "password": password,
      "confirmpassword": confirmpassword,
      "role": "company",
      "landline":landline,
      "province":province,
      "city":city,
      "barangay":barangay,
      "is_vat" : (SelectedVAT =='vt')? 1 : 0,
      "trade_name":trade_name,
      "tin": tin,
      "psic": psic,
      "reg_address":reg_address,
      "line_business":line_business,
      "is_top_withhold_agent":selectedAgentOption,
      "tax_types": Taxtypes
    };


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
      body: JSON.stringify(postData)
    };

   await fetch(process.env.REACT_APP_API_URL+'api/register', requestOptions)
      .then( async response => 
        {
          console.log(response.status);  
          // Check the status code
          if (response.status === 422) {
            // API call succeeded

            const data = await response.json();
            console.log(data.message);  
            // handleData(data.message);
            setEmailInvalid(true);
          }else{
            const data = await response.json();
            console.log(response.status);  
             
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Successfully Register',
              showConfirmButton: false,
              timer: 1500
          })  
            navigate('/');
          }
        }
        
      )
      .then(data => {
        // Handle the response
        console.log(data);
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
      setCompletesubmit(false);
  };


 
  
  const LoadProvince = async () => {
    



    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
    };

   await fetch(process.env.REACT_APP_API_URL+'api/province', requestOptions)
      .then( async response => 
        {
            console.log(response);
          // Check the status code
          if (response.status === 422) {
            // API call succeeded

            const data = await response.json();
            console.log(data.message);  
            // handleData(data.message);
         
          }else{
            const data = await response.json();
            console.log(data.data);  
            setListProvince(data.data);
          }
        }
        
      )
      .then(data => {
        // Handle the response
        console.log(data);
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
     
  };


  
  const LoadCity = async (value) => {
    

    const postData = {
      "provCode": value,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
      body: JSON.stringify(postData)
    };

   await fetch(process.env.REACT_APP_API_URL+'api/city', requestOptions)
      .then( async response => 
        {
            console.log(response);
          // Check the status code
          if (response.status === 422) {
            // API call succeeded

            const data = await response.json();
            console.log(data.message);  
     
          }else{
            const data = await response.json();
            console.log(data.data);  
            setListCity(data.data);
          }
        }
        
      )
      .then(data => {
        // Handle the response
        console.log(data);
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
     
  };


  
  const LoadBarangay = async (value) => {
    

    const postData = {
      "citymunCode": value,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
      body: JSON.stringify(postData)
    };

   await fetch(process.env.REACT_APP_API_URL+'api/barangay', requestOptions)
      .then( async response => 
        {
            console.log(response);
          // Check the status code
          if (response.status === 422) {
            // API call succeeded

            const data = await response.json();
            console.log(data.message);  
     
          }else{
            const data = await response.json();
            console.log(data.data);  
            setListBarangay(data.data);
          }
        }
        
      )
      .then(data => {
        // Handle the response
        console.log(data);
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
     
  };

  function checkPasswordStrength(password) {
    // Define regex patterns for different character types
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/;
  
    // Check length
    if (password.length < 8) {
      return 'Weak (password should be at least 8 characters)';
    }
  
    // Check for different character types
    const hasLowercase = lowercaseRegex.test(password);
    const hasUppercase = uppercaseRegex.test(password);
    const hasDigit = digitRegex.test(password);
    const hasSpecialChar = specialCharRegex.test(password);
  
    // Evaluate strength based on character types
    if (hasLowercase && hasUppercase && hasDigit && hasSpecialChar) {
      return 'Strong';
    }
  
    if ((hasLowercase && hasUppercase && hasDigit) || (hasLowercase && hasUppercase && hasSpecialChar) || (hasLowercase && hasDigit && hasSpecialChar) || (hasUppercase && hasDigit && hasSpecialChar)) {
      return 'Medium';
    }
  
    return 'Weak';
  }
  

  const [isStepOneVisible, setStepOneVisible] = useState(true);
  const [isStepTaxPayerVisible, setisStepTaxPayerVisibleVisible] = useState(false);
  const [isStepTwoVisible, setStepTwoVisible] = useState(false);
  const [isStepCompleteVisible, setStepCompleteVisible] = useState(false);
  const [isErrorPasswordVisible, setErrorPasswordVisible] = useState(false);
  const [isErroraggreement, setErroraggreement] = useState(false);
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const [isCompletesubmit, setCompletesubmit] = useState(false);
  

  const handleChange = (event) => {
    const { name, value } = event.target;
 
    if (name === "province") {
      LoadCity(value);
    }
    if (name==="city"){
      LoadBarangay(value);
      
    }
    if(name==='password'){

      if(value !=''){
        setshowPasswordButton(true);
      }else{
        setshowPasswordButton(false);
      }

  }
  if(name==='confirmpassword'){

    if(value !=''){
      setshowConfirmPasswordButton(true);
    }else{
      setshowConfirmPasswordButton(false);
    }
  }

  if(name==='tin'){
    setFormFields({ ...formFields, [name]: formatTinFull(value) });
    return; // Exit the function early to prevent setting the value again
  }

  
    if (name==="mobile"){
    
      setFormFields({ ...formFields, [name]: formatMobileNumber(value) });
      return; // Exit the function early to prevent setting the value again
    }
    setFormFields({ ...formFields, [name]: value });
  };

  


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

    console.log(isChecked);
  };


  const formatMobileNumber =(value)=>{

    let numericValue = value.replace(/\D/g, '');

    // Check if the input starts with the country code +63
    if (numericValue.startsWith('639')) {
      // If it does, keep it as it is
      numericValue = `+639 ${numericValue.substring(3)}`;
    } else if (numericValue.length >= 1) {
      // If not, add the country code +63
      numericValue = `+639 ${numericValue}`;
    } else {
      // If the input is empty, clear the input value
      numericValue = '';
    }

    return numericValue;
  }



  
  const [showPasswordButton, setshowPasswordButton] = useState(false);

  const [showConfirmPasswordButton, setshowConfirmPasswordButton] = useState(false);

 


//  const handleChangePassword = (event) => {
//     const { name, value } = event.target;

//     if(name==='currentpassword'){

//         if(value !=''){
//           setshowPasswordButton(true);
//         }else{
//           setshowPasswordButton(false);
//         }

//     }
    

//   }
//     const formattedValue =  value;
//     setFormFields({ ...formFields, [name]: formattedValue });
//   };

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setisConfirmPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setisNewPasswordVisible] = useState(false);

  const togglePassword = (value) => {


    if(value==='password'){

      setPasswordVisible(prevState => !prevState);

    }
    
    if(value==='confirmpassword'){

      setisConfirmPasswordVisible(prevState => !prevState);
    }
    

  }


  
  
 
  
    const updateTaxTypes = (idToTrue, idToFalse) => {
      const updatedTaxTypes = Taxtypes.map((tax) => {
        if (tax.id === idToTrue) {
          return { ...tax, checked: true };
        }
        if (tax.id === idToFalse) {
          return { ...tax, checked: false };
        }
        return tax;
      });
      console.log(updatedTaxTypes);
      // setTaxtypes(updatedTaxTypes);


    };
    const updateTaxTypesFalseAll = (idToTrue, idToFalse) => {
      const updatedTaxTypes = Taxtypes.map((tax) => {
        if (tax.id === idToTrue) {
          return { ...tax, checked: false };
        }
        if (tax.id === idToFalse) {
          return { ...tax, checked: false };
        }
        return tax;
      });
      console.log(updatedTaxTypes);
      // setTaxtypes(updatedTaxTypes);


    };

  return (
    <>
      <div>
        <div className="lg:pl-72 lg:pr-72  uppercase">
      
          <main className="py-10">
            <div className= {isCompletesubmit ? "opacity-20 px-4 sm:px-6" : "px-4 sm:px-6 lg:px-8"}>
              <div>
              <nav aria-label="Progress">
                <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                  {steps.map((step, stepIdx) => (
                    <li key={step.name} className="relative md:flex md:flex-1">
                      {step.status === 'complete' ? (
                        <a href={step.href} className="group flex w-full items-center">
                          <span className="flex items-center px-6 py-4 text-sm font-medium">
                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                              <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                            <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                          </span>
                        </a>
                      ) : step.status === 'current' ? (
                        <a href={step.href} className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                            <span className="text-blue-600">{step.id}</span>
                          </span>
                          <span className="ml-4 text-sm font-medium text-blue-600">{step.name}</span>
                        </a>
                      ) : (
                        <a href={step.href} className="group flex items-center">
                          <span className="flex items-center px-6 py-4 text-sm font-medium">
                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                              <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                            </span>
                            <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                          </span>
                        </a>
                      )}

                      {stepIdx !== steps.length - 1 ? (
                        <>
                          {/* Arrow separator for lg screens and up */}
                          <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                            <svg
                              className="h-full w-full text-gray-300"
                              viewBox="0 0 22 80"
                              fill="none"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M0 -2L20 40L0 82"
                                vectorEffect="non-scaling-stroke"
                                stroke="currentcolor"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </>
                      ) : null}
                    </li>
                  ))}
                </ol>
              </nav>
           
                {isStepOneVisible ?
                   <form onSubmit={handleSubmitStepOne} >
                      <StepFormOne  ListBarangay={ListBarangay} ListCity={ListCity} ListProvince={ListProvince} province={province} city={city} barangay={barangay} company={company} description={description} email={email} mobile={mobile} landline={landline} street={street} businessType={businessType} handleChange={handleChange} SelectedVAT={SelectedVAT} handleVATChange={handleVATChange} />   
                    </form>   
                : ''}
                 {isStepTaxPayerVisible?
                  <form onSubmit={handleSubmitTaxInfo} >
                     <StepTaxPayer trade_name={trade_name}
                      tin={tin} psic={psic} reg_address={reg_address}
                        line_business={line_business} handleChange={handleChange} 
                        Taxtypes={Taxtypes} handleCheckboxChangeTax={handleCheckboxChangeTax}
                        setselectedAgentOption={setselectedAgentOption} handleRadioAgentChange={handleRadioAgentChange}
                        TopAgentRadio={TopAgentRadio}  selectedAgentOption={selectedAgentOption}
                        BackTaxPayer={BackTaxPayer} updateTaxTypes={updateTaxTypes} updateTaxTypesFalseAll={updateTaxTypesFalseAll}/>
                  </form>
                   
                    :''
                  }
                  {isStepTwoVisible?
                  <form onSubmit={handleSubmitStepTwo} >
                     <StepFormTwo email={email} password={password} confirmpassword={confirmpassword}
                      handleChange={handleChange} 
                      isErrorPasswordVisible={isErrorPasswordVisible}
                       isErrorPasswordText={isErrorPasswordText}


                       setshowConfirmPasswordButton={setshowConfirmPasswordButton} 
                       setisConfirmPasswordVisible={setisConfirmPasswordVisible}
                       showConfirmPasswordButton={showConfirmPasswordButton}
                       isConfirmPasswordVisible={isConfirmPasswordVisible}

                       togglePassword={togglePassword}
                       setPasswordVisible={setPasswordVisible}
                       isPasswordVisible={isPasswordVisible}
                       showPasswordButton={showPasswordButton}
                       BackStepTwo={BackStepTwo}/>
                  </form>
                   
                    :''
                  }
                   {isStepCompleteVisible ?
                   <form onSubmit={handleSubmitComplete} >
                     <StepFormComplete handleCheckboxChange={handleCheckboxChange} isChecked={isChecked} isErroraggreement={isErroraggreement} isEmailInvalid={isEmailInvalid} BackStepThree={BackStepThree}/>
                  </form>
                   
                    :''
                  }
               {/* <StepFormComplete/> */}
               
             
             </div>

             
            </div>
            {isCompletesubmit?
              <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" >
                  <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                  <span class="sr-only">Loading...</span>
              </div>   
            :''}
      
          </main>
        </div>
      </div>
    </>
   
  )
}
export default StepOneReg;