 
import { ExclamationTriangleIcon, EyeDropperIcon, EyeIcon } from '@heroicons/react/20/solid'
import { UserContext } from '../../../contexts/user.context'
// import { useEffect,useState,useContext } from 'react'
import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import PaginatedTable from '../../../components/pagination/paginatedtable.component'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon,PencilSquareIcon  } from '@heroicons/react/24/outline'
import { Document, Page, Text, View, PDFViewer,Image,StyleSheet   } from '@react-pdf/renderer';
import { formatAmountDisplay } from '../../../utils/ValidationField'
const JournalListLayout =(props) =>{


    const [isLoad, setLoad] = useState(true);

    const [allList, setallList] = useState([]);
    const [Pagination, setPagination]= useState([]);
    const { company_id } = useContext(UserContext);

    const [Searchdata, setInputValue] = useState('');

    const [open, setOpen] = useState(false);



    const defaultFormFields = {
      ItemId:'',
      Series:'',
      Date:'',
      Remarks: '',
      Encoder: '',
      Total: '',
    };
    const [formFields,setFormFields ] = useState(defaultFormFields);
    const { Series,Date,Remarks,Encoder,Total} = formFields;



    const [EntryList, setEntryList] = useState([]);


    const OpenModalItem=async (id,series,date,remark,user,total) => {

      

      formFields.ItemId=id;
      formFields.Series=series;
      formFields.Date=date;
      formFields.Remarks=remark;
      formFields.Encoder=user;
      formFields.Total=total;

      const postData = {
        "id": id
      };
     
        console.log(postData);


   
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/journal/items', requestOptions)
      .then( async response => 
        {

            console.log(response);
          // Check the status code
          if (response.status === 201) {

        
             const data = await response.json();
          
             console.log(data);

             setEntryList(data.data);
           

          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
        setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });

      
      setOpen(true);
    }

   
    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        LoadAllList();
      };
    
      const [isNodata, setNodata] = useState(false);

      const handlePageChangeParent = (newPage) => {
        setallList([]);
        LoadAllList(newPage)
      };
      const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };

      const LoadAllList = async (page = 1) => {

        const postData = {
            "company_id": company_id,
            "searchdata":Searchdata,
            "perpage": 10,
            "page":page
          };
         
            console.log(postData);
  
  
       
          const requestOptions = {
            method: 'POST',
            headers: 
            { 'Content-Type': 'application/json',
            'Accept': 'application/json', 
             'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
            body: JSON.stringify(postData)
          };
      
       await fetch(process.env.REACT_APP_API_URL+'api/journal/list', requestOptions)
          .then( async response => 
            {
  
                console.log(response);
              // Check the status code
              if (response.status === 201) {
  
            
                 const data = await response.json();
              
                 console.log(data);
   
   
                  setallList(data.data); 
                  setPagination(data.pagination);
         
   
              }else{
           
                setNodata(false);
                
              }
            }
            
          )
          .then(data => {
            // Handle the response
         
        
            setLoad(true);
          })
          .catch(error => {
            // Handle any errors
         
          });
          setLoad(true);


      }

      useEffect( () => {
        setLoad(false);

        LoadAllList();
       
     
    }, []); 


  
    
    return (
        <div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">General Journal</h1>
           
          </div>
   

        </div>

        <div className='flex mt-4'>
            <div className=''>
                <div className='mt-2'>


                </div>
            </div>
            <div className='w-9/12'>
            <form onSubmit={handleSubmitSearch}>
                  <div class="relative mt-4 w-3/5 mx-auto">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                          </svg>
                      </div>
                      <input type="search"
                      id="default-search" 
                      class=" block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                      placeholder="Search Series..." 
                      value={Searchdata}
                      onChange={handleInputChange}
                      />
                      <button type="submit"   class="capitalize text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                  </div>
              </form>
            </div>
        </div>    
            
 
        <div className= {isLoad? 'mt-2 flow-root uppercase ':'mt-2 flow-root uppercase rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
           
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                      <th scope="col" className=" py-1 pl-1 pr-1  text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        JE#
                      </th>
                      <th scope="col" className=" py-1 pl-1 pr-1  text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date
                      </th>
                      <th scope="col" className=" py-1 pl-1 pr-1  text-left text-sm font-semibold text-gray-900">
                        Account Debit
                      </th>
                          
                      <th scope="col" className=" py-1 pl-1 pr-1  text-left text-sm font-semibold text-gray-900">
                      Account Credit
                      </th>
                      <th scope="col" className=" py-1 pl-1 pr-1  text-left text-sm font-semibold text-gray-900">
                        Amount DR/CR
                      </th>
                      <th scope="col" className=" py-1 pl-1 pr-1  text-left text-sm font-semibold text-gray-900">
                        REMARKS
                      </th>
                      <th scope="col" className=" py-1 pl-1 pr-1  text-left text-sm font-semibold text-gray-900">
                      Encoder
                      </th>
                    

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                  {allList.map((item) => (
                      <tr key={item.id}>
                                  <td className="relative whitespace-nowrap  py-1 pl-1 pr-1  text-right text-sm font-medium sm:pr-6">
                      <button   

                        onClick={() => OpenModalItem(
                          item.id,
                          item.series,
                          item.date,
                          item.remark,
                          item.name.toLowerCase(),
                          formatAmountDisplay(item.amount)
                          )}
                        class="text-black-600 hover:text-black-900">
                          
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      </svg>



                      <span class="sr-only">{item.id}</span></button>
                      </td>
                        <td className="relative whitespace-nowrap  py-1 pl-1 pr-1   text-sm sm:pr-6 ">{item.series} </td>
                        <td className="relative whitespace-nowrap  py-1 pl-1 pr-1   text-sm  sm:pr-6 ">{item.date} </td>
                        <td className="relative whitespace-nowrap  py-1 pl-1 pr-1  text-sm  sm:pr-6 ">{item.Debit_Account} {(item.count_item>1)? <span className='text-blue-500'>*</span>:'' }</td>
                        <td className="relative whitespace-nowrap  py-1 pl-1 pr-1   text-sm  sm:pr-6 ">{item.Credit_Account} </td>

                        <td className="relative whitespace-nowrap  py-1 pl-1 pr-1  text-right text-sm font-medium sm:pr-6 ">{formatAmountDisplay(item.amount)} </td>

                        <td className="relative whitespace-nowrap  py-1 pl-1 pr-1   text-sm sm:pr-6 "> {item.remark}</td>
                        <td className="whitespace-nowrap  py-1 pl-1 pr-1  text-sm text-gray-500 capitalize">{item.name.toLowerCase()}</td>
                     </tr>
                    ))}
                  </tbody>
                </table>
                {allList.length==0 && isNodata?
                <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                        
                        <div className="mt-2 text-sm text-yellow-700 ">
                            <p>
                                    Data Not Found
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                :
                ''}
                 <div className="container mx-auto p-4">
                  <PaginatedTable data={allList} pagination={Pagination} handlePageChangeParent={handlePageChangeParent}/>
                </div>

              </div>
            </div>
          </div>
        </div>
       
      

        <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity " />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto item-center ">
                        <div className="flex min-h-screen items-center justify-center p-4 text-center sm:p-0 mt-10 ">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl md:max-w-3xl lg:max-w-3xl 2xl:max-w-4xl sm:p-6 mx-auto">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => {
                                      setOpen(false);
                                      
                                      // Call your second function here
                                  }}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>

                                  <h1 className='text-2xl font-semibold leading-6 text-blue-600 uppercase'> Details  </h1>
                                    <div className="mt-3 text-center sm:mt-5 ">

                                    <div className="grid grid-cols-2 gap-4 mt-4">
                                            <div>
                                              <div className='flex mt-4'>
                                                  <div className='w-2/5 font-bold text-left'>
                                                    Series:
                                                  </div>
                                                  <div className='w-3/5	text-left capitalize'>
                                                    {Series}
                                                  </div>
                                              </div>
                                            </div>


                                            <div>
                                                <div className='flex mt-4'>
                                                  <div className='w-2/5 font-bold text-left'>
                                                    Date:
                                                  </div>
                                                  <div className='w-3/5	text-left capitalize'>
                                                    {Date}
                                                  </div>
                                             
                                              </div>
                                            </div>
                                        </div>


                                        <div className="grid grid-cols-2 gap-4 mt-4">
                                            <div>
                                              <div className='flex mt-4'>
                                                  <div className='w-2/5 font-bold text-left'>
                                                    Remark:
                                                  </div>
                                                  <div className='w-3/5	text-left capitalize'>
                                                      {Remarks}
                                                  </div>
                                              </div>
                                            </div>
                                            <div>
                                                <div className='flex mt-4'>
                                                  <div className='w-2/5 font-bold text-left'>
                                                    Encoder:
                                                  </div>
                                                  <div className='w-3/5	text-left capitalize'>
                                                    {Encoder}
                                                  </div>
                                             
                                              </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='mt-4 border-2 border-black'>

                               
                                      {EntryList.map((item, index) => (
                                          <>
                                         
                                        <div className="grid grid-cols-2  pl-2 border-black border-b">
                                            <div>
                                              <div className='flex'>
                                                  <div className='w-2/5 text-left font-bold'>
                                                    {item.type}
                                                  </div>
                                                  <div className={(item.type=='Debit')?  'w-2/5 text-left' : 'w-2/5 text-left ml-24'}>
                                                    {item.account}
                                                  </div>
                                                 
                                              </div>
                                            </div>
                                            <div>
                                                <div className={(item.type=='Debit')? 'flex  ' :'flex  ml-24'}>
                                                  <div className='w-2/5  text-left'>
                                                  {formatAmountDisplay(item.amount)}
                                                  </div>
                                                 
                                             
                                              </div>
                                            </div>

                                        </div>
                                          </>
                                        )
                                        )}
                                    
                                     
                                    </div>
                                    <h1 className='text-2xl font-bold text-right'>Total: {Total}</h1>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>


                    
         </Transition.Root>
      </div>
      );

   
}
export default JournalListLayout;