// src/utils.js




export const formatAmount = (value) => {
  // Remove non-digit characters except for dots
  // Remove non-digit characters except for dots and minus signs
  const cleanedValue = value.replace(/[^\d.]/g, '');
  // Convert the cleaned string to a number to handle cases like '1,000' or '1.000'
  const numberValue = parseFloat(cleanedValue);

  // Check if the conversion was successful
  if (isNaN(numberValue)) {
    return ''; // Or return the original value if you prefer
  }

 
  // Use Intl.NumberFormat for better formatting
  let x = cleanedValue.toString().replace(/,/g, '');
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
  x = x.replace(pattern, "$1,$2");

  return x;
};
export const formatAmountDisplay = (value) => {

  
  // Remove non-digit characters except for dots and minus signs
  const cleanedValue = String(value).replace(/[^0-9.-]/g, '');

  // Convert the cleaned string to a number to handle cases like '1,000' or '1.000'
  const numberValue = parseFloat(cleanedValue);

  // Check if the conversion was successful
  if (isNaN(numberValue)) {
    return ''; // Or return the original value if you prefer
  }

  // Use Intl.NumberFormat for better formatting
  const formattedValue = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numberValue);

  return formattedValue;
};


export const toSentenceCase = (text) => {
  return text
      .toLowerCase() // Convert the entire text to lowercase
      .replace(/(?:^|\.\s*)([a-z])/g, (match, p1) => p1.toUpperCase()); // Capitalize first letter after a period and space
};

export const SentenceCaseText = ({ text }) => {
  const sentenceCaseText = toSentenceCase(text);

  return <p>{sentenceCaseText}</p>;
};

export const formatAddress = (address) => {
  // Convert to uppercase as an example
  return address.toUpperCase();
};

export const Address = ({ address }) => {
  const formattedAddress = formatAddress(address);

  return <p>{formattedAddress}</p>;
};

  

  


  export const formatTin = (value) => {
    // Remove existing dashes and non-digit characters
    const sanitizedValue = value.replace(/[^0-9]/g, '');
  
    // Add a dash every 4 characters and 7 characters
    const formattedValue = sanitizedValue.replace(/(\d{3})(\d{3})?(\d{0,3})?/, (_, p1, p2, p3) => {
      let result = p1;
      if (p2) result += `-${p2}`;
      if (p3) result += `-${p3}`;
      return result;
    });
  
    return formattedValue;
  };


  export const formatTinFull = (value) => {
    // Remove existing dashes and non-digit characters
    const sanitizedValue = value.replace(/[^0-9]/g, '');
  
    // Add a dash every 3 characters and 3 characters
    const formattedValue = sanitizedValue.replace(/(\d{3})(\d{3})?(\d{3})?(\d{0,3})?/, (_, p1, p2, p3, p4) => {
      let result = p1;
      if (p2) result += `-${p2}`;
      if (p3) result += `-${p3}`;
      if (p4) result += `-${p4}`;
      return result;
    });
  
    return formattedValue;
  };