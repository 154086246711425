 
import { ExclamationTriangleIcon, EyeDropperIcon, EyeIcon } from '@heroicons/react/20/solid'

// import { useEffect,useState,useContext } from 'react'
import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import { UserContext } from '../../contexts/user.context';
import { Document, Page, Text, View, PDFViewer,Image,StyleSheet   } from '@react-pdf/renderer';
import { SentenceCaseText,formatAmountDisplay } from '../../utils/ValidationField';
import $ from 'jquery'; 
import DataTable from 'datatables.net';
import './DataTableStyles.css'; // Your custom styles
import 'select2';
const GeneralLedgerLayout =() =>{

  

  const [allClass, setClass] = useState([]);

  const [LedgerList, setLedgerLists] = useState([]);

  const [TotalDebit, setTotalDebit] = useState(0);
  const [TotalCredit, setTotalCredit] = useState(0);
  const [BalanceTotal, setBalanceTotal] = useState(0);

  const [InitialBeggining, setInitialBeggining] = useState(true);

  const [InitialClass, setInitialClass] = useState([]);

  const [classificationSelected, setclassificationSelected] = useState([]);
  const updateTotals = (debit, credit,balance) => {
    setTotalDebit(debit);
    setTotalCredit(credit);
    setTotalCredit(credit);
    setBalanceTotal(balance);
};
  const defaultFormFields = {
    fromdate: '',
    todate:'',
    classification:''
  };
  const [formFields,setFormFields ] = useState(defaultFormFields);
  const { fromdate,todate,classification} = formFields;

  const handleChange = (event) => {
    const { name, value } = event.target;
 
    
    setFormFields({ ...formFields, [name]: value });
  };

  const { company_id } = useContext(UserContext);
  const selectRefClass = useRef(null);
  useEffect( () => {
  
    LoadClassList();
    GeneralLedgerInitial();
    
  
    $(selectRefClass.current).select2({
      placeholder: "--Account--",
      allowClear: true,
    });



    const handleChangeClass = (event) => {
      const selectedValues = $(event.target).val(); // Get selected values
      setclassificationSelected(selectedValues); // Update state with selected values
      console.log("Selected values:", selectedValues); // Log selected values
  };
  
  
  $(selectRefClass.current).on('change', handleChangeClass);
  
  // Cleanup function
  return () => {
      $(selectRefClass.current).off('change', handleChangeClass);
      $(selectRefClass.current).select2('destroy');
  };
}, []); 
const [isCompletesubmit, setCompletesubmit] = useState(false);

  const LoadClassList = async () => {



    const postData = {

      "company_id": company_id,
   
    };
   
    
    
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

  await fetch(process.env.REACT_APP_API_URL+'api/setting/account/list/all', requestOptions)
      .then( async response => 
        {
          console.log(response);
          // Check the status code
          if (response.status === 422) {

            
          }else{
            const data = await response.json();
     
            setClass(data.data); 

            
          }
        }
        
      )
      .then(data => {
        // Handle the response
    
    
        
      })
      .catch(error => {
        // Handle any errors
    
      });
    
  } 

  const GenerateLedger = async () => {
    setLedgerLists([]);
    setCompletesubmit(true);

  
      const postData = {
        "company_id": company_id,
        "start_date":fromdate,
        "end_date":todate,
        "account":classificationSelected
       
      };


     
      console.log(postData);
       
      console.log('postData');
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/general/ledger', requestOptions)
      .then( async response => 
        {
          console.log(response);
           
          // Check the status code
          if (response.status === 422) {
       
             setCompletesubmit(false);
          }else{
            const data = await response.json();

          
            setCompletesubmit(false);
            setLedgerLists(data.data);
            
            console.log(data);
                   
           console.log('ledger');
      
            // updateTotals(data.total_debit, data.total_credit,data.balance);
        
        

            console.log(data.data);
          }
          setInitialBeggining(false);
        }
        
      )
      .then(data => {
        // Handle the response
     
    
        
      })
      .catch(error => {
        // Handle any errors
     
      });
    

      setCompletesubmit(false);
  } 
  useEffect(() => {
    window.$ = $;
    window.jQuery = $;

    const initializeDataTables = () => {
        InitialClass.forEach(item => {
          
          const tableId = `#myTable-${item.account.replace(/[^a-zA-Z0-9]/g, '')}`;
            // Check if the DataTable is already initialized
            if (!$.fn.DataTable.isDataTable(tableId)) {
                $(tableId).DataTable({
                    paging: true,
                    searching: false,
                    ordering: false,
                });

                // Add description if needed
                if (item.shouldAddDescription) {
                    const descriptionElement = document.createElement('div');
                    descriptionElement.className = 'description';
                    descriptionElement.innerText = item.description;
                    $(tableId).before(descriptionElement);
                }

                console.log(`Initialized: ${tableId}`);
            }
        });
    };

    // Initialize DataTables
    initializeDataTables();

    // // Cleanup function
    // return () => {
    //     InitialClass.forEach(item => {
    //         const tableId = `#myTable-${item.account.replace(/\s+/g, '')}`;
    //         if ($.fn.DataTable.isDataTable(tableId)) {
    //             $(tableId).DataTable().destroy(true);
    //             console.log(`Destroyed: ${tableId}`);
    //         }
    //     });
    // };
}, [InitialClass]);

  const GeneralLedgerInitial = async () => {
    
    setCompletesubmit(true);

  
      const postData = {
        "company_id": company_id,
      };

      console.log(postData);
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/general/initial/ledger', requestOptions)
      .then( async response => 
        {
          console.log(response);
           
          // Check the status code
          if (response.status === 422) {
       
             setCompletesubmit(false);
          }else{
            const data = await response.json();

          
            setCompletesubmit(false);
            setInitialClass(data.data)

          
    
       
            
              
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
        
      })
      .catch(error => {
        // Handle any errors
     
      });
    

      setCompletesubmit(false);
  } 




  

  const styles = {
    container: {
      padding: 20,
      
    },
    centeredRow: {
      justifyContent: 'center', // Center items in this specific row
    
      fontWeight: 'bold',
      padding: 2,
      marginTop: 10, // Adjust this value as needed for spacing
  },
    row: {
      flexDirection: 'row',
    borderBottomWidth: 1, // Add border bottom
    borderColor: 'gray', // Border color
 
    },
    label: {
      width: 200,
      fontWeight: 'bold',
    },
    value: {
      flex: 1,
      textTransform: 'capitalize' // Capitalize text
    },

    rowExpense: {
      flexDirection: 'row',
    
    },
    cell: {
      width: '20%', // Adjust the width of each cell as needed
      padding: 2,
      border: '1px solid black',
      textTransform: 'capitalize', // Capitalize text
      fontSize:12
    },
    amountText: {
      textAlign: 'right', // Align the amount text to the right
      fontWeight: 'bold',  // Optional: make it bold for emphasis
  },
  };

  const { Tin ,Trade_name ,Reg_address,OwnerName,username} = useContext(UserContext);

  const manilaDate = new Date().toLocaleDateString('en-CA', {
    timeZone: 'Asia/Manila',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

    return (
        <div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">General Ledger</h1>
           
          </div>
   

        </div>
        <form>
            <div className="max-w-6xl bg-gray-50 p-6 rounded-lg shadow-md mt-10 mx-auto">
                <div className="grid grid-cols-9 gap-4">
                    <div className="col-span-3">
                        <div className="flex items-center">
                            <label for="classification" className="text-sm font-medium text-gray-700 mr-4">Account</label>
                            <select
                               ref={selectRefClass}
                               multiple
                               className="p-2 w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                id="classification"
                                name="classification"
                                value={classificationSelected}
                                
                                required
                            >
                             
                                {allClass.map((Classification) => (
                                    <option key={Classification.account} value={Classification.account}>
                                        {Classification.account}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-span-3">
                      <div className="flex items-center">
                        <label for="fromdate" className="text-sm font-medium text-gray-700 mr-4 w-32">From Date</label>
                        <input
                            type="date"
                            id="fromdate"
                            name="fromdate"
                            className="w-full p-2 border rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            value={fromdate}
                            required
                            onChange={handleChange}
                        />
                    </div>

                    </div>
                    <div className="col-span-3">
                    <div className="flex items-center">
                        <label for="fromdate" className="text-sm font-medium text-gray-700 mr-4 w-32">To Date</label>
                        <input
                            type="date"
                            id="todate"
                            name="todate"
                            className="w-full p-2 border rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            value={todate}
                            required
                            onChange={handleChange}
                        />
                    </div>

                    </div>
                </div>
                <div className="mt-4 flex justify-center">
                    <button
                        type="button"
                        onClick={() => GenerateLedger()}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>

        { isCompletesubmit?   <div className="max-w-3xl  mt-10 mx-auto flex items-center justify-center">
          <div role="status">
              <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
              <span className="sr-only">Loading...</span>
          </div> 
       </div>   : ''
      }

      {(InitialBeggining)?
      <>
       <div className="max-w-screen-lg mx-auto mt-10 h-screen">
         {
          InitialClass.map((Rpt) => ( 
            
            <>
              <h1 className="text-lg font-semibold leading-6 text-gray-900 mb-2 mt-2">{Rpt.account}</h1>


              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300"  id={`myTable-${Rpt.account.replace(/\s+/g, '')}`}  key={`myTable-${Rpt.account.replace(/\s+/g, '')}`}>
                <thead className="bg-gray-50">
                  <tr>

                    <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Date
                    </th>
                    <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900">
                       Particular
                    </th>
                    <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900">
                       Reference
                    </th>
                    <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900">
                      Debit
                    </th>
                      <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900">
                        Credit
                      </th>
                      <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900">
                        Balance
                      </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Rpt.ledger.map((ledg) => (
                        <tr key={ledg.account}>
                              <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                              {ledg.date} 
                          </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                              {ledg.account} 
                          </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                              {ledg.reference} 
                          </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm text-gray-500"> {(ledg.type=='Debit')? formatAmountDisplay(ledg.amount): ''} </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm text-gray-500"> {(ledg.type=='Credit')? formatAmountDisplay(ledg.amount): ''} </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm text-gray-500">  </td>
                    
                          
                        </tr>
                      ))}
                        <tr key={'tr'+Rpt.account}>
                              <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                              
                          </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                              
                          </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                             
                          </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm text-gray-500">{(formatAmountDisplay(Rpt.debit))} </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm text-gray-500"> {formatAmountDisplay((Rpt.credit))} </td>
                          <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm text-gray-500">{(Rpt.balance>=0)? formatAmountDisplay(Rpt.balance) : '('+formatAmountDisplay(Math.abs(Rpt.balance))+')'}</td>
                    
                          
                        </tr>
                </tbody>
              </table>



              </div>
            </>
          ))
         }
         
       </div>
      </>:
       <div className="max-w-screen-lg mx-auto mt-10 h-screen">
          
       <PDFViewer className="w-full h-full">
       <Document>
       <Page size={{ width: 1000, height: 1000 }} style={styles.container}>
           <View >
           <View>
           <Text style={{  }}>TIN: {Tin} </Text>
           <Text style={{  }}>OWNER'S NAME: {OwnerName}</Text>
           <Text style={{  }}>OWNER'S TRADE NAME: {Trade_name}</Text>
           <Text style={{  marginBottom:10}}>OWNER'S ADDRESS: {Reg_address}</Text>
           <Text style={[styles.row, styles.centeredRow]}>DATE: {fromdate} - {todate}</Text>
           </View>
          
           {LedgerList.map((Rpt) => (
              <View key={'view'+Rpt.account}>
              {/* Centered Title Row */}
              <View style={[styles.row, styles.centeredRow]}>
                  <Text style={styles.cell}>{Rpt.account}</Text>
              </View>
              
              {/* Header Row */}
              <View style={styles.row}>
                  <Text style={styles.cell}>Date</Text>
                  <Text style={styles.cell}>Particular</Text>
                  <Text style={styles.cell}>Reference</Text>
                  <Text style={styles.cell}>Debit</Text>
                  <Text style={styles.cell}>Credit</Text>
                  <Text style={styles.cell}>Balance</Text>
              </View>
      
              {/* Data Rows */}
              {Rpt.ledger.map((item, itemIndex) => (
                  <View key={itemIndex} style={styles.row}>
                      <Text style={styles.cell}>{item.date}</Text>
                      <Text style={styles.cell}>{item.particular}</Text>
               
                      <Text style={styles.cell}>{item.reference}</Text>
                      <Text style={[styles.cell, styles.amountText]}>{(item.type=='Debit')? formatAmountDisplay(item.amount) :''}</Text>
                      <Text style={[styles.cell, styles.amountText]}>{(item.type=='Credit')? formatAmountDisplay(item.amount) :''}</Text>
                      <Text style={styles.cell}></Text>
                      
                  </View>
              ))}
               <View  style={styles.row}>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                <Text style={styles.cell}></Text>
                
                <Text style={[styles.cell, styles.amountText]}>{ formatAmountDisplay(Rpt.debit) }</Text>
                <Text style={[styles.cell, styles.amountText]}>{formatAmountDisplay(Rpt.credit) }</Text>
                <Text style={styles.cell}>{ (Rpt.balance>=0)? formatAmountDisplay(Math.abs(Rpt.balance)) :  '('+formatAmountDisplay(Math.abs(Rpt.balance))+')'}</Text>
              
              </View>
            
          </View>
        ))}
          
          

           {/* <View  style={styles.row}>
             <Text style={styles.cell}></Text>
             <Text style={styles.cell}></Text>
             <Text style={styles.cell}></Text>
             <Text style={[styles.cell, styles.amountText]}>{ formatAmountDisplay(TotalDebit) }</Text>
             <Text style={[styles.cell, styles.amountText]}>{formatAmountDisplay(TotalCredit) }</Text>
          
           </View>
           <View  style={styles.row}>
             <Text style={styles.cell}></Text>
             <Text style={styles.cell}></Text>
             <Text style={styles.cell}></Text>
             <Text style={[styles.cell, styles.amountText]}>{(TotalDebit>TotalCredit)? 'Balance: '+formatAmountDisplay(BalanceTotal):'' }</Text>
             <Text style={[styles.cell, styles.amountText]}>{(TotalDebit<TotalCredit)? 'Balance: '+formatAmountDisplay(BalanceTotal):'' }</Text>
          
           </View> */}
           
           </View>
           <View style={styles.row}>
               <Text style={{  marginTop: 10  }}>Username: {username} </Text>
               <Text style={{  marginTop: 10  }}> </Text>
               <Text style={{ marginTop: 10  }}> </Text>
               <Text style={{  marginTop: 10  }}>Date Print: {manilaDate} </Text>
           </View>
         </Page>
       </Document>
       </PDFViewer>
 
 
       </div>
 
    }
     

      </div>
      );

   
}
export default GeneralLedgerLayout;