import { useState,useEffect,useContext,Fragment,useRef } from 'react'


import { PencilSquareIcon,ExclamationTriangleIcon,Cog6ToothIcon, XMarkIcon, BuildingStorefrontIcon, XCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import { SentenceCaseText } from '../../utils/ValidationField';
import { Dialog, Transition } from '@headlessui/react'
import { UserContext } from '../../contexts/user.context'
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { PlusIcon } from '@heroicons/react/20/solid'
import SettingNavigation from './SettingNavigation.layout';


const SettingFSMapping =() =>{


      const pages = [
          { name: 'FS Mapping', href: '#', current: false },
        ]
      

     const { company_id } = useContext(UserContext);

    
     const [isLoad, setLoad] = useState(true);
     const [open, setOpen] = useState(false)
     const cancelButtonRef = useRef(null)

    const [FSLine, setFSLine] = useState([]);

    const [FsDropLine, setFsDropLine] = useState([]);

    const defaultFormFields = {
        TitleId:'',
        Subtitle_id:'',
        Subtitle:'',
        SubItemTitle:'',
        ItemAction:'Add',
        Description:'',
        Level:'',
        TypeFS:'FSLINE'
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { Description,Level,TitleId,Subtitle_id,TypeFS} = formFields;
    
      const [isCompletesubmit, setCompletesubmit] = useState(false);

      const indexToLetter = (index) => {
        return String.fromCharCode(97 + index);  // 'a' is at 97 in Unicode
      };
    
    useEffect( () => {
    
    
        LoadFsLine();
        
        LoadFSLineClass();
    }, []); 

  
    const hadleChangeFSLINE = async(fs_type, class_account,class_id,fsline_value,text) => {


        console.log(class_id);
       

        if(fsline_value !=''){
            const postData = {
                "class_id": class_id,
                "class_type": class_account,
                "fs_type": fs_type,
                "company_id": company_id,
                "fs_line_id":fsline_value
              };
          
       
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/fs/mapping/update/create', requestOptions)
              .then( async response => 
                {
                
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                    
    
                    console.log(data)
      
                  
                  }else{
                    const data = await response.json();
        
                    console.log(data)
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
        }else{
            const postData = {
                "class_id": class_id,
                "class_type": class_account,
                "company_id": company_id,
              };
          
       
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/fs/mapping/delete', requestOptions)
              .then( async response => 
                {
                
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                    
    
                    console.log(data)
      
                  
                  }else{
                    const data = await response.json();
        
                    console.log(data)
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
        }

       

    }
    const LoadFsLine = async () => {


      setLoad(true);
      const postData = {
        "company_id": company_id,
      };
     


        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
           body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/fs/mapping', requestOptions)
        .then( async response => 
          {

            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
              setLoad(false);
               
            }else{
              const data = await response.json();
              setFSLine(data.data); 
              console.log(data.data);
           
              setLoad(false);
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(false);
        })
        .catch(error => {
          // Handle any errors
          setLoad(false);
        });
      
    } 


    const LoadFSLineClass = async () => {

     
  
        const postData = {
          "company_id": company_id,
        };
       
        
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/fs/mapping/dropdown', requestOptions)
        .then( async response => 
          {
            // Check the status code
            if (response.status === 422) {
  
              //  setNodata(false);
               
            }else{
              const data = await response.json();
              setFsDropLine(data.data); 
       
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
         
        })
        .catch(error => {
          // Handle any errors
       
        });
       
    }


    const handleSubmit = async (event) => {
        console.log('xx');
        event.preventDefault();
    
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        
              
        if(Description =='' ){
        
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Complete The Data',
                
                })
        
        
            return;
        }
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "Do you want to save",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          reverseButtons: true
        }).then(async (result) => {
          if (result.isConfirmed) {
    
            setCompletesubmit(true);

            if(formFields.ItemAction=='Add'){
              const postData = {
                "fs_title_id": TitleId,
                "fs_sub_title_id": Subtitle_id,
                "company_id": company_id,
                "name": Description,
                "level":Level,
                "type":TypeFS
              };
          
       
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/fsline/store', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                
                    setCompletesubmit(false);
      
                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                    setCompletesubmit(false);
  
  
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Create new entry successfully',
                      showConfirmButton: false,
                      timer: 1500 // 1.5 seconds
                  }).then(() => {
                      // After the Swal is closed (timer ends), reload the page
                      window.location.reload();
                  });
                  
      
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });


            }else{
              const postData = {
                "id": TitleId,
                "name": Description,
              };
          
       
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/fsline/update', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                
                    setCompletesubmit(false);
      
                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                    setCompletesubmit(false);
                  
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Update successfully',
                      showConfirmButton: false,
                      timer: 1500 // 1.5 seconds
                  }).then(() => {
                      // After the Swal is closed (timer ends), reload the page
                      window.location.reload();
                  });
                  
      
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
            }

            
           
    
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
    
          }
        })
       
      }; 


    
    return (
        <div>
     
        <nav className="flex mt-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                <div>
                    <a href="#" className="text-gray-400 hover:text-gray-500">
                    <Cog6ToothIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
            {pages.map((page) => (
            <li key={page.name}>
                <div className="flex items-center">
                <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                >
                    {page.name}
                </a>
                </div>
            </li>
            ))}
        </ol>
        </nav>

        <div className="flex gap-4">
        <div className="w-3/12">
                <aside className="flex  border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                  <SettingNavigation pageIndex={8}/>
                </aside>
             </div>
             <div className="w-9/12	">
                <main className="px-4 py-2 sm:px-6 lg:flex-auto lg:px-0 mt-12">
                <div class="max-w-3xl  mt-10 mx-auto flex items-center justify-center">

                  {isLoad? 

                <div role="status">
                  <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                  <span class="sr-only">Loading...</span>
                </div> 
                  :''  
                }
                 
                </div>
                    <div >
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg ">
                                   
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>

                                        <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            ACCOUNTS
                                        </th>
                                        <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900">
                                            FS
                                        </th>
                                        <th scope="col" className="py-1 pl-1 pr-1 text-left text-sm font-semibold text-gray-900">
                                            FS LINE
                                        </th>
                                       
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {FSLine.map((fs) => (
                                        <tr key={fs.id}>
                                            <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm font-medium text-gray-900 sm:pl-6 capitalize">
                                                {fs.account} 
                                            </td>
                                            <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm text-gray-500"> {fs.fs_type} </td>
                                            <td className="whitespace-nowrap py-1 pl-1 pr-1 text-sm text-gray-500"> 

                                            
                                                {(FsDropLine.filter((FS) => FS.type === fs.fs_type).length==0)?
                                                <p className='text-orange-500'>FS Line Setup Required</p>
                                                :    

                                                <select  
                                                className=" p-2.5 block w-full
                                                    rounded-md border-0 py-1.5 text-gray-900 
                                                    shadow-sm ring-1 ring-inset ring-gray-300
                                                    placeholder:text-gray-400 focus:ring-2
                                                    focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    name="modalVatclass"
                                                    onChange={(e) => {
                                                        const selectedOption = e.target.selectedOptions[0];
                                                        const value = e.target.value; // Get the value
                                                        const title = selectedOption.getAttribute('title'); // Get the title
                                                        const text = selectedOption.text; // Get the text
                                                        hadleChangeFSLINE(fs.fs_type,fs.account,fs.id,  value, title, text); // Pass all values
                                                      }}
                                                    required>
                                                        <option ></option>

                                                       
                                                        {FsDropLine.map((FS) => (
                                                        <>
                                                            {(fs.fs_type==FS.type)?
                                                               
                                                               <option value={FS.id} title={fs.id} 

                                                               selected={parseInt(fs.fs_line) === parseInt(FS.id)} 
                                                                

                                                                  >{FS.name}</option>
                                                        
                                                        :''}
                                                             
                                                        </>
                                                        ))}
                                                    
                                            </select>
                                            }
                                                   
                                            </td>

                                         
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        </div>
              
       
                </main>
             </div>


        </div>
      </div>);

}
export default SettingFSMapping;