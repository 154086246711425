import { Fragment,useEffect, useState } from 'react'
import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import { useNavigate } from 'react-router-dom'

import { formatAmountDisplay } from '../../utils/ValidationField'

const MobileQrCode =() =>{

  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false)


  return (
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen} />

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 border border-gray-100">
            <div className="flex h-42 shrink-0 items-center   mt-10">
              <img
                className="h-42 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                alt="Your Company"
              />
            </div>
            <SidebarNavigation activepage={'DOWNLOAD MOBILE'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8 ">
            <section className="bg-white px-6 py-24 sm:py-32 lg:px-8">
            <figure className="mx-auto max-w-2xl">
                <p className="sr-only">5 out of 5 stars</p>
                <div className="flex gap-x-1 text-indigo-600">
               
                </div>
                <blockquote className="mt-10 text-xl/8 font-semibold tracking-tight text-gray-900 sm:text-2xl/9 text-center">
                <p>
                 Scan the QR Code to Download
                </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center ">
                <img
                    alt=""
                    src={process.env.PUBLIC_URL + '/QRC.png'} 
                    className="w-3/6 mx-auto bg-gray-50"
                />
      
                </figcaption>
            </figure>
            </section>
        
            </div>
          </main>
        </div>
      </div>
    </>
   
  )
}
export default MobileQrCode;