import { useState,useEffect,useContext,Fragment,useRef } from 'react'


import { PencilSquareIcon,ExclamationTriangleIcon,Cog6ToothIcon, UserIcon, BuildingStorefrontIcon } from '@heroicons/react/20/solid'
import { SentenceCaseText } from '../../utils/ValidationField';

import { UserContext } from '../../contexts/user.context'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/24/outline'
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import SettingNavigation from './SettingNavigation.layout';
import {
  TagIcon
} from '@heroicons/react/24/outline'


const SettingWithholdingRates =() =>{
   
    const { company_id } = useContext(UserContext);
    const [isLoad, setLoad] = useState(true);

    

      const pages = [
          { name: 'Withholding Rates Category', href: '#', current: false },
        ]
      
        const [allList, setallList] = useState([]);
       const [isNodata, setNodata] = useState(false);
        const [open, setOpen] = useState(false)
        const cancelButtonRef = useRef(null)
        const [CategoryList, setCategoryList] = useState([]);


     useEffect( () => {

   
            LoadCategoryList();
           
    }, []); 

    const handlePercentageChange = (id, value) => {
        setallList(prevList =>
            prevList.map(item =>
                item.id === id ? { ...item, rate: parseFloat(value) } : item
            )
        );

        console.log(allList);
    };
    


        
    const LoadCategoryList = async () => {

        console.log(company_id);

        const postData = {
          "company_id": company_id,
        };
       
        
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/setting/withholding/category', requestOptions)
        .then( async response => 
          {
             console.log(response);
            // Check the status code
            if (response.status === 422) {

               setNodata(false);
               
            }else{
              const data = await response.json();
              setallList(data.data); 
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);
    } 


    const SaveRateWithhold =  () => {

        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        
  
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "Do you want to save",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          reverseButtons: true
        }).then(async (result) => {
          if (result.isConfirmed) {
  
            let CategoryRates =[];
  
            allList.forEach(element => {
              
              if(document.getElementById(element.id+'rate')){
                const object = { 
                  id: element.id,
                   rate: (document.getElementById(element.id+'rate').value !='' && document.getElementById(element.id+'rate').value !=null)? parseFloat(document.getElementById(element.id+'rate').value) :0,
                };
  
                CategoryRates.push(object);
              }
             
            });
            
     
        
            const postData = {
             "category_rates":CategoryRates,
              "company_id":company_id
            };
  
            const requestOptions = {
              method: 'POST',
              headers: 
              { 'Content-Type': 'application/json',
              'Accept': 'application/json', 
               'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
              body: JSON.stringify(postData)
            };
    
            await fetch(process.env.REACT_APP_API_URL+'api/setting/withholding/category/rates', requestOptions)
            .then( async response => 
              {
                  console.log(response);
                // Check the status code
                if (response.status === 422) {
                  // API call succeeded
    
                  const data = await response.json();
              
                
                }else{
                  const data = await response.json();
                
                  console.log(data);
                
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Update rate successfully',
                    showConfirmButton: false,
                    timer: 1500
                })  
                }
              }
              
            )
            .then(data => {
              // Handle the response
              console.log(data);
            })
            .catch(error => {
              // Handle any errors
              console.error(error);
            });
  
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
    
          }
        })
        console.log(allList);
  
       
      }
    
    return (
        <div>
     
        <nav className="flex mt-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                <div>
                    <a href="#" className="text-gray-400 hover:text-gray-500">
                    <Cog6ToothIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
            {pages.map((page) => (
            <li key={page.name}>
                <div className="flex items-center">
                <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                >
                    {page.name}
                </a>
                </div>
            </li>
            ))}
        </ol>
        </nav>

        <div className="flex gap-12 ">
        <div className="w-3/12">
                <aside className="flex  border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                  <SettingNavigation pageIndex={4}/>
                </aside>
             </div>
             <div className="w-9/12	">
                <main className="px-4 py-2 sm:px-6 lg:flex-auto lg:px-0 ">

                    <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root  rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>

                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Category
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Rates
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {allList.map((Withholding) => (
                                  (Withholding.category!='Others')?
                                    <tr key={Withholding.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {Withholding.category}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            <input
                                                id={Withholding.id + "rate"}
                                                name={Withholding.id + "rate"}
                                                type="number"
                                                value= {Withholding.rate}
                                                onChange={(e) => handlePercentageChange(Withholding.id, e.target.value)}
                                                className="px-4 block w-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                        </td>
                                    </tr> :''
                                ))}
                                </tbody>
                                </table>
                  
                                {allList.length==0 && isNodata?
                                <div className="rounded-md bg-yellow-50 p-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                        
                                        <div className="mt-2 text-sm text-yellow-700 ">
                                            <p>
                                                    Data Not Found
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''}

                            </div>
                            </div>
                        </div>
                        </div>
              
                        <div className="mt-6 flex items-center  gap-x-6">
                      
                            <button
                            type="button"
                            onClick={SaveRateWithhold}
                            className="rounded-md bg-indigo-600 px-4 py-2 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                            Save
                            </button>
                        </div>
                </main>
             </div>


        </div>
      </div>);

}
export default SettingWithholdingRates;