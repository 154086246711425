import { useState,useEffect,useContext,Fragment,useRef } from 'react'


import { PencilSquareIcon,ExclamationTriangleIcon,Cog6ToothIcon, XMarkIcon, BuildingStorefrontIcon, XCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import { SentenceCaseText } from '../../utils/ValidationField';
import { Dialog, Transition } from '@headlessui/react'
import { UserContext } from '../../contexts/user.context'
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { PlusIcon } from '@heroicons/react/20/solid'
import SettingNavigation from './SettingNavigation.layout';


const SettingFsLine =() =>{


      const pages = [
          { name: 'FS LINE', href: '#', current: false },
        ]
      

     const { company_id } = useContext(UserContext);

    
     const [isLoad, setLoad] = useState(true);
     const [open, setOpen] = useState(false)
     const cancelButtonRef = useRef(null)

    const [FSLine, setFSLine] = useState([]);


    const defaultFormFields = {
        TitleId:'',
        Subtitle_id:'',
        Subtitle:'',
        SubItemTitle:'',
        ItemAction:'Add',
        Description:'',
        Level:'',
        TypeFS:'FSLINE'
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { Description,Level,TitleId,Subtitle_id,TypeFS} = formFields;
    
      const [isCompletesubmit, setCompletesubmit] = useState(false);

      const indexToLetter = (index) => {
        return String.fromCharCode(97 + index);  // 'a' is at 97 in Unicode
      };
    
    useEffect( () => {
    
    
        LoadFsLine();
        
       
    }, []); 

    const OpenModalFsLine= (title_id,subtitle_id,subtitle,level) => {
        setOpen(true);
        formFields.TitleId=title_id;
        formFields.Subtitle_id=subtitle_id;
        if(level==3){
          formFields.Subtitle=subtitle +' (FS LINE)';
          formFields.Description='';
        }else{
          if(title_id==1){
            formFields.Subtitle=subtitle +' (Item)';
          }else{
            formFields.Subtitle=subtitle +' (FS LINE)';
          }
        
        }
       
        formFields.ItemAction='Add';
        formFields.Level=level;
        formFields.TypeFS='FSLINE'
    }

    const OpenModalNoteLine= (title_id,subtitle_id,subtitle,level) => {
      setOpen(true);
      formFields.TitleId=title_id;
      formFields.Subtitle_id=subtitle_id;
      formFields.Subtitle=subtitle +' (NOTE LINE)';
      formFields.Description='';
 
     
      formFields.ItemAction='Add';
      formFields.Level=level;
      formFields.TypeFS='NOTELINE'
  }

    const OpenModalFsLineEdit= (title_id,subtitle_id,subtitle,level) => {
      setOpen(true);
      formFields.TitleId=title_id;
      formFields.Subtitle_id=subtitle_id;
      formFields.Subtitle='';
      formFields.Description=subtitle;
      formFields.ItemAction='Update';
      formFields.Level=level;
  }
    const handleChange = (event) => {
        const { name, value } = event.target;

      const formattedValue =value;
     
     
    
      
        setFormFields({ ...formFields, [name]: formattedValue });

      };

    const LoadFsLine = async () => {


      setLoad(true);
      const postData = {
        "company_id": company_id,
      };
     


        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
           body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/fsline/items', requestOptions)
        .then( async response => 
          {

            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
              setLoad(false);
               
            }else{
              const data = await response.json();
              setFSLine(data.data); 

              console.log(data.data);
              setLoad(false);
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(false);
        })
        .catch(error => {
          // Handle any errors
       
        });
      
    } 


    const handleSubmit = async (event) => {
        console.log('xx');
        event.preventDefault();
    
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        
              
        if(Description =='' ){
        
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Complete The Data',
                
                })
        
        
            return;
        }
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "Do you want to save",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          reverseButtons: true
        }).then(async (result) => {
          if (result.isConfirmed) {
    
            setCompletesubmit(true);

            if(formFields.ItemAction=='Add'){
              const postData = {
                "fs_title_id": TitleId,
                "fs_sub_title_id": Subtitle_id,
                "company_id": company_id,
                "name": Description,
                "level":Level,
                "type":TypeFS
              };
          
       
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/fsline/store', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                
                    setCompletesubmit(false);
      
                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                    setCompletesubmit(false);
  
  
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Create new entry successfully',
                      showConfirmButton: false,
                      timer: 1500 // 1.5 seconds
                  }).then(() => {
                      // After the Swal is closed (timer ends), reload the page
                      window.location.reload();
                  });
                  
      
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });


            }else{
              const postData = {
                "id": TitleId,
                "name": Description,
              };
          
       
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/fsline/update', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                
                    setCompletesubmit(false);
      
                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                    setCompletesubmit(false);
                  
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Update successfully',
                      showConfirmButton: false,
                      timer: 1500 // 1.5 seconds
                  }).then(() => {
                      // After the Swal is closed (timer ends), reload the page
                      window.location.reload();
                  });
                  
      
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
            }

            
           
    
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
    
          }
        })
       
      }; 


      const DeleteItem = async (id,description) => {
       
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        
     
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "Do you want to delete "+description +"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          reverseButtons: true
        }).then(async (result) => {
          if (result.isConfirmed) {
    
            setCompletesubmit(true);

             const postData = {
                "id": id,
              };
          
       
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/fsline/delete', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                
                    setCompletesubmit(false);
      
                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                    setCompletesubmit(false);
  
  
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Successfully Delete',
                      showConfirmButton: false,
                      timer: 1500 // 1.5 seconds
                  }).then(() => {
                      // After the Swal is closed (timer ends), reload the page
                      window.location.reload();
                  });
                  
      
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });

              
            
           
    
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
    
          }
        })
       
      }; 
    return (
        <div>
     
        <nav className="flex mt-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                <div>
                    <a href="#" className="text-gray-400 hover:text-gray-500">
                    <Cog6ToothIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
            {pages.map((page) => (
            <li key={page.name}>
                <div className="flex items-center">
                <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                >
                    {page.name}
                </a>
                </div>
            </li>
            ))}
        </ol>
        </nav>

        <div className="flex gap-4">
        <div className="w-3/12">
                <aside className="flex  border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                  <SettingNavigation pageIndex={7}/>
                </aside>
             </div>
             <div className="w-9/12	">
                <main className="px-4 py-2 sm:px-6 lg:flex-auto lg:px-0 mt-12">
                <div class="max-w-3xl  mt-10 mx-auto flex items-center justify-center">

                  {isLoad? 

                <div role="status">
                  <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                  <span class="sr-only">Loading...</span>
                </div> 
                  :''  
                }
                 
                </div>
                    <div >
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg ">
                                    {FSLine.map((item, index) => (
                                        <div className="px-4 sm:px-0 mx-4 pt-4" key={index}>
                                            <h1 className="text-xl font-bold text-gray-900 underline">{item.title}</h1>

                                            {item.subitem.map((subitem, subindex) => (

                                                <>
                                                <div key={subindex}>
                                                  
                                                    <div className='flex gap-4'>
                                                        <div>
                                                            <p className="ml-4 mt-1 max-w-2xl text-lg text-gray-500 font-semibold">
                                                            {subitem.subtitle}
                                                            </p>
                                                        </div>
                                                        <div>
                                                        
                                                        {(item.id==2)? 
                                                          <button
                                                          type="button"
                                                          onClick={() => OpenModalFsLine(item.id, subitem.id,subitem.subtitle,2)}
                                                          className="inline-flex items-center rounded-md bg-blue-600 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                          
                                                          ADD FS LINE 
                                                        </button>
                                                        :
                                                        <>
                                                        {/* <div className='flex gap-4' >
                                                            <div>
                                                              <button
                                                                type="button"
                                                                onClick={() => OpenModalFsLine(item.id, subitem.id,subitem.subtitle,2)} // use arrow function to defer execution
                                                                className="underline mt-2 rounded-full bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                >
                                                                <PlusIcon aria-hidden="true" className="h-5 w-5" />
                                                              </button>
                                                            </div>
                                                            
                                                        </div> */}
                                                        
                                                      
                                                        </>
                                                   
                                                        
                                                        }

                                                        
                                               
                                                        </div>
                                                    </div>
                                                   
                                                </div>



                                               {subitem.sub_subitem.map((subsubitem, subsubindex) => (
                                                <>

                                                 <div key={subsubindex}>
                                                    <div className='flex gap-4'>
                                                        <div>
                                                            <p className="ml-10 mt-1 max-w-2xl text-sm text-gray-500 font-semibold">
                                                            {(item.id==2)? <span className='uppercase'>{indexToLetter(subsubindex)+')'} </span>: ''}  {subsubitem.name}
                                                            <button
                                                              type="button"
                                                              onClick={() => OpenModalFsLineEdit(subsubitem.id, subitem.id,subsubitem.name,3)} // use arrow function to defer execution
                                                              className="ml-4 underline mt-2 rounded-full bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                              >
                                                              <PencilSquareIcon aria-hidden="true" className="h-5 w-5" />
                                                            </button>

                                                            <button
                                                              type="button"
                                                              onClick={() => DeleteItem(subsubitem.id,subsubitem.name)} // use arrow function to defer execution
                                                              className="ml-4 underline mt-2 rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                              >
                                                              <TrashIcon aria-hidden="true" className="h-5 w-5" />
                                                            </button>
                                                            {(item.id==2)? 
                                                            <>
                                                            <button
                                                              type="button"
                                                                  onClick={() => OpenModalNoteLine(subsubitem.id, subitem.id,subsubitem.name,4)}
                                                                  className="ml-4 mt-4 inline-flex items-center rounded-md bg-orange-600 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                                                >
                                                                  
                                                                  ADD NOTE LINE
                                                                </button>
                                                            </>

                                                            
                                                            :''}

                                                       
                                            
                                                            
                                                            </p>
                                                        </div>
                                                        
                                                        <div>
                                                          {(subitem.fs_title_id==1)? 
                                                          
                                                          <>
                                                         
                                                              <div className='flex gap-2  mt-4'>
                                                      
                                                                <div>
                                                                  <button
                                                                      type="button"

                                                                      onClick={() => OpenModalFsLine(subsubitem.id, subitem.id,subsubitem.name,3)}
                                                                      className="inline-flex items-center rounded-md bg-blue-600 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                    >
                                                                      
                                                                      ADD FS LINE
                                                                    </button>
                                                                    </div>
                                                              </div>


                                                          </>
                                                          :''}
                                                  
                                                        </div>
                                                    </div>
                                                    
                                                  </div>
                                                            
                                                  {subsubitem.sub_subitem.map((subsubsubitem, subsubsubindex) => (
                                                  <>
                                                  
                                                 <div key={subsubsubindex}>
                                                    <div className='ml-12 flex gap-4'>
                                                      
                                                        <div>
                                                            <p className="ml-10 mt-1 max-w-2xl text-sm text-gray-500 font-semibold underline">
                                                            <span className='uppercase'>{indexToLetter(subsubsubindex)+')'} </span>{subsubsubitem.name}         
                                                              <button
                                                              type="button"
                                                              onClick={() => OpenModalFsLineEdit(subsubsubitem.id, subitem.id,subsubsubitem.name,3)} // use arrow function to defer execution
                                                              className="ml-4 underline mt-2 rounded-full bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                              >
                                                              <PencilSquareIcon aria-hidden="true" className="h-5 w-5" />
                                                            </button>

                                                            <button
                                                              type="button"
                                                              onClick={() => DeleteItem(subsubsubitem.id,subsubsubitem.name)} // use arrow function to defer execution
                                                              className="ml-4 underline mt-2 rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                              >
                                                              <TrashIcon aria-hidden="true" className="h-5 w-5" />
                                                            </button>
                                                            </p>
                                                            <span>
                                                              
                                                            </span>
                                                        </div>
                                                        <div>
                                                              <button
                                                              type="button"

                                                              onClick={() => OpenModalNoteLine(subsubsubitem.id, subitem.id,subsubsubitem.name,4)}
                                                              className="mt-4 inline-flex items-center rounded-md bg-orange-600 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                                            >
                                                              
                                                              ADD NOTE LINE
                                                            </button>
                                                        </div>
                                               
                                                    </div>
                                                    
                                                  </div>

                                                  {subsubsubitem.note_line.map((note_line_item, note_lineindex) => (
                                                    <>
                                                        
                                                    <div key={note_lineindex}>
                                                        <div className='ml-24 flex gap-4'>
                                                          
                                                            <div>
                                                            <p className="ml-10 mt-1 max-w-2xl text-sm text-orange-500 font-semibold underline decoration-orange-500">
                                                                <span className='uppercase'>{indexToLetter(note_lineindex)+')'} </span>{note_line_item.name}         
                                                       
                                                                </p>
                                                                <span>
                                                                  
                                                                </span>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                onClick={() => OpenModalFsLineEdit(note_line_item.id, note_line_item.id,note_line_item.name,3)} // use arrow function to defer execution
                                                                className="ml-4 underline mt-2 rounded-full bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                >
                                                                <PencilSquareIcon aria-hidden="true" className="h-5 w-5" />
                                                              </button>

                                                              <button
                                                                type="button"
                                                                onClick={() => DeleteItem(note_line_item.id,note_line_item.name)} // use arrow function to defer execution
                                                                className="ml-4 underline mt-2 rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                                >
                                                                <TrashIcon aria-hidden="true" className="h-5 w-5" />
                                                              </button>
                                                      
                                                  
                                                        </div>
                                                        
                                                      </div>
                                                    </>
                                                  ))}


                                 
                                                  </>

                                                
//
                                                 
                                                ))} 

                                                             

                                                { (subitem.fs_title_id==2)?
                                                    
                                                    subsubitem.note_line.map((note_line_item, note_lineindex) => (
                                                      <>
                                                      <div key={note_lineindex}>
                                                          <div className='ml-24 flex gap-4'>
                                                            
                                                              <div>
                                                              <p className="ml-10 mt-1 max-w-2xl text-sm text-orange-500 font-semibold underline decoration-orange-500">
                                                                  <span className='uppercase'>{indexToLetter(note_lineindex)+')'} </span>{note_line_item.name}         
                                                        
                                                                  </p>
                                                                  <span>
                                                                    
                                                                  </span>
                                                              </div>
                                                                <button
                                                                type="button"
                                                                onClick={() => OpenModalFsLineEdit(note_line_item.id, note_line_item.id,note_line_item.name,3)} // use arrow function to defer execution
                                                                className="ml-4 underline mt-2 rounded-full bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                >
                                                                <PencilSquareIcon aria-hidden="true" className="h-5 w-5" />
                                                              </button>
                                              
                                                              <button
                                                                type="button"
                                                                onClick={() => DeleteItem(note_line_item.id,note_line_item.name)} // use arrow function to defer execution
                                                                className="ml-4 underline mt-2 rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                                >
                                                                <TrashIcon aria-hidden="true" className="h-5 w-5" />
                                                              </button>
                                              
                                                          </div>
                                                          
                                                        </div>
                                                      </>
                                              
                                                    ))
                                                                                                        
                                                      :''

                                                        }
                                                </>
                                            
                                               ))} 

                                     

                                                </>
                                            
                                             


                                            ))}
                                        </div>
                                        ))}



                                </div>
                            </div>
                        </div>
                        </div>
              
       
                </main>
             </div>


             <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                         <h1 class="text-base font-semibold leading-6 text-gray-900 text-left " >{formFields.ItemAction} {formFields.Subtitle} </h1>
                                        <div className="mt-2">
                                      

                                        <div className="grid grid-cols-1">
                                          
                                            <form  onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 ">
                                                    <div className="px-4 py-6 sm:p-8">
                                                        <div className="grid grid-cols-1 ">
                                                        

                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="Description" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                                    Description
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                    type="text"
                                                                    id="Description"
                                                                    name="Description"
                                                                    value={Description}
                                                                    onChange={handleChange}
                                                                    className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            </div>
                                                        </div>
                                                
                                                    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
                                                        <button
                                                        type="submit"
                                                    
                                                        className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                        Save
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>
        </div>
      </div>);

}
export default SettingFsLine;