import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import { UserContext } from '../../contexts/user.context'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
// import './sweetbutton.css'; //
// import Swal from 'sweetalert2';
import { formatAmount } from '../../utils/ValidationField';
import PaginatedTable from '../../components/pagination/paginatedtable.component'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
const HistoryPage =() =>{

  const [isLoad, setLoad] = useState(true);

  const [allList, setallList] = useState([]);
  
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { user_id,company_id } = useContext(UserContext);

  const [Searchdata, setInputValue] = useState('');

  const [isNodata, setNodata] = useState(false);
  const [Pagination, setPagination]= useState([]);

  const handleSubmitSearch = (event) => {
    event.preventDefault();
    setNodata(true);
    LoadAllList();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

    const handlePageChangeParent = (newPage) => {
      // LoadPendingList(newPage);
      setallList([]);
      LoadAllList(newPage)

    };

  useEffect(() => {

    LoadAllList()
  
  }, []);
const LoadAllList = async (page = 1) => {


    const postData = {
      "company_id": company_id,
      "searchdata":Searchdata,
    
      "perpage": 10,
      "page":page
    };
   
      console.log(postData);

    
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
      body: JSON.stringify(postData)
    };

 await fetch(process.env.REACT_APP_API_URL+'api/history', requestOptions)
    .then( async response => 
      {

        console.log(response);
     
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

         // const data = await response.json();
       
          // handleData(data.message);
           setNodata(false);
           
        }else{
          const data = await response.json();
          console.log(data);
           setallList(data.data); 
           setPagination(data.pagination);
          //  props.setshowSummaryCreate(false);
          //  props.setshowListPending(true);

       
          
        }
      }
      
    )
    .then(data => {
      // Handle the response
   
  
      setLoad(true);
    })
    .catch(error => {
      // Handle any errors
   
    });
    setLoad(true);
} 


    return(
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 border border-gray-100">
            <div className="flex h-42 shrink-0 items-center   mt-10">
              <img
                className="h-42 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'Activity Log'}  />
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
                <div className="divide-y divide-gray-900/10">
                  
             
                <form onSubmit={handleSubmitSearch}>
                    <div class="relative mt-4 w-1/3">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="search"
                        id="default-search" 
                        class="uppercase block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="Search ..." 
                        value={Searchdata}
                        onChange={handleInputChange}
                        />
                        <button type="submit"   class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                    </div>
                </form>
                <div className= {isLoad? 'mt-2 flow-root uppercase ':'mt-2 flow-root uppercase rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>

                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Date Time Encoded
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              User
                            </th>
                          
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Activity
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Data
                            </th>
                                
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Type
                            </th>
                         
                
            

                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">Edit</span>
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">Edit</span>
                            </th>
                          
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {allList.map((user) => (
                      <tr key={user.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {user.created_at} 
                        </td>
                        <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">{user.user}</td>
                        <td className="px-2 py-4 text-sm text-gray-500">{user.activity}</td>
                        <td className=" px-2 py-4 text-sm text-gray-500">{user.data}</td>
                     
                        <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">{user.type}</td>
                       
                         
                  
                      </tr>
                    ))}
                        </tbody>
                      </table>
                      {allList.length==0 && isNodata?
                      <div className="rounded-md bg-yellow-50 p-4">
                          <div className="flex">
                              <div className="flex-shrink-0">
                              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                              </div>
                              <div className="ml-3">
                              
                              <div className="mt-2 text-sm text-yellow-700 ">
                                  <p>
                                          Data Not Found
                                  </p>
                              </div>
                              </div>
                          </div>
                      </div>
                      :
                      ''}
                      <div className="container mx-auto p-4">
                        <PaginatedTable data={allList} pagination={Pagination} handlePageChangeParent={handlePageChangeParent}/>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


                </div>
             </div>
            </div>
          </main>
        </div>
      </div>


      
  </>);

}
export default HistoryPage;