

import { UserContext } from '../../contexts/user.context'
import { useEffect,useState,useContext } from 'react'
import { Document, Page, Text, View, PDFViewer,Image,StyleSheet   } from '@react-pdf/renderer';
import { formatAmount,formatTin,SentenceCaseText,formatAmountDisplay } from '../../utils/ValidationField';


const RptDisapprove =(props) =>{

    const [isLoad, setLoad] = useState(true);

    const [pendinglist, setPendingList] = useState([]);
    const [Pagination, setPagination]= useState([]);
    const { company_id } = useContext(UserContext);
    const [Searchdata, setInputValue] = useState('');
    const [allClass, setClass] = useState([]);

    const [withsummary, setWithSummary] = useState(false);

    const handleToggle  = (event) => {
      setWithSummary(event.target.checked);
      console.log(withsummary);
    };

    const [isCompletesubmit, setCompletesubmit] = useState(false);

    const { Tin ,Trade_name ,Reg_address,OwnerName,username} = useContext(UserContext);

    const manilaDate = new Date().toLocaleDateString('en-CA', {
      timeZone: 'Asia/Manila',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });


    useEffect( () => {

      LoadClassList();

  }, []); 


  const styles = {
    container: {
      padding: 20,
      
    },
    row: {
      flexDirection: 'row',
    borderBottomWidth: 1, // Add border bottom
    borderColor: 'gray', // Border color
    paddingBottom: 10, // Add padding to separate rows
    },
    label: {
      width: 200,
      fontWeight: 'bold',
      textTransform: 'capitalize' // Capitalize text
    },
    value: {
      flex: 1,
      textTransform: 'capitalize' // Capitalize text
    },
    imageContainer: {
      marginBottom: 8,
      alignItems: 'center',
    },

    rowExpense: {
      flexDirection: 'row',
    
    },
    cell: {
      width: '20%', // Adjust the width of each cell as needed
      padding: 2,
      border: '1px solid black',
      textTransform: 'capitalize' // Capitalize text
    },
  };
  
  

     const [showPDF, setShowPDF] = useState(false);

    // const generatePDF = () => {
    //   setShowPDF(true);
    // };
    const [isNodata, setNodata] = useState(false);

    

    const defaultFormFields = {
        fromdate: '',
        todate:'',
        classification:''
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { fromdate,todate,classification} = formFields;
    

    const handleChange = (event) => {
        const { name, value } = event.target;
     
        
        setFormFields({ ...formFields, [name]: value });
      };


    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        ReportListExpense();
        setShowPDF(true);
       
      };

    const SearchExpense=() =>{
       
        ReportListExpense();
        setShowPDF(true);
    }
    const ReportListExpense = async (page = 1) => {

      setCompletesubmit(true);
      setPendingList([]);
      console.log(classification);

      console.log(fromdate);
     
      console.log(page);
        const postData = {
          "company_id": company_id,
          "startdate":fromdate,
          "enddate":todate,
        //   "classification":classification,
        //   "withsummary": withsummary,
          "page":page
        };


        console.log(postData);
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/report/expense_disapproved', requestOptions)
        .then( async response => 
          {
             
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
               setNodata(false);
               setCompletesubmit(false);
            }else{
              const data = await response.json();
              console.log(data.data);
              console.log(data.data);
              setPendingList(data.data); 
             setPagination(data.pagination);
              props.setshowSummaryCreate(false);
              props.setshowListPending(true);
              setCompletesubmit(false);
           
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);

        setCompletesubmit(false);
    } 

    const ConvertDate =(date) =>{

      if(date ==null || date==''){
        return ''
      }
      const fromdate = new Date(date); // January 1, 2024
  
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
  
     return fromdate.toLocaleDateString('en-US', options);
  
    }
    const LoadClassList = async () => {



      const postData = {
        "search":Searchdata,
        "company_id": company_id,
    
      };
     
      
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/list', requestOptions)
      .then( async response => 
        {
           console.log(response);
          // Check the status code
          if (response.status === 422) {

             
          }else{
            const data = await response.json();
      
            setClass(data.data); 

         
            
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
        setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      setLoad(true);
  } 


    const inputDateStr = "AUGUST 21, 2023";

    // Parse the input date string into a Date object
    const parsedDate = new Date(inputDateStr);


    const formattedDate = parsedDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).split('/').join('-'); // Replace "/" with "-"


    // function fetchImage(imageUrl) {
    //   return axios.get('https://pentagontechno.com/api/images/'+imageUrl, { responseType: 'arraybuffer' })
    //     .then(response => {
    //       const base64Image = btoa(new Uint8Array(response.data).reduce((data, byte) => {
    //         return data + String.fromCharCode(byte);
    //       }, ''));
    //       return `data:image/jpeg;base64,${base64Image}`;
    //     })
    //     .catch(error => {
    //       console.error('Error fetching image:', error);
    //       return null; // You can handle the error as needed
    //     });
    // }
    
    return (
        <div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-lg text-gray-700 font-bold">Report</h1>
            <p className="mt-2 text-lg text-gray-700 font-bold">
               Expense Disapproved
            </p>
          </div>

        </div>
       
        <form >
            <div class="max-w-xl bg-gray-50 p-6 rounded-lg shadow-md mt-10 mx-auto">
                <div class="grid grid-cols-8 gap-4">
                  <div class="col-span-4">
                    <div class="flex items-center">
                      <label for="fromdate" class="text-sm font-medium text-gray-700 mr-4">From Date</label>
                          <input type="date"
                          id="fromdate" 
                          name="fromdate"
                          class="w-full p-2 border rounded-md
                          border-gray-300 shadow-sm
                          focus:border-indigo-300 focus:ring
                          focus:ring-indigo-200 focus:ring-opacity-50"
                          value={fromdate}
                          required
                          onChange={handleChange}
                          />
                      </div>
                  </div> 
                  <div className="col-span-4">
                    <div className="flex items-center">
                      <label for="start-date" className="text-sm font-medium text-gray-700 mr-4">To Date</label>
                          <input
                            type="date"
                            id="todate" 
                            name="todate" 
                            className="w-full p-2 border 
                            rounded-md border-gray-300
                            shadow-sm focus:border-indigo-300 
                            focus:ring focus:ring-indigo-200
                              focus:ring-opacity-50"
                              value={todate}
                              required
                              onChange={handleChange}
                          />
                      
                    </div>   
                  </div> 

                  {/* <div className="col-span-2">
                    <div className="flex items-center">
                      <label for="start-date" className="text-sm font-medium text-gray-700 mr-4">With Summary</label>
                      <input
                            type="checkbox"
                            id="withsummary" 
                            name="withsummary" 
                            className="w-full p-2 border 
                              focus:ring-opacity-50"
                              required
                              checked={withsummary}
                              onChange={handleToggle}
                          />
                      
                    </div>   
                  </div>  */}
                </div>
                <div class="mt-4 flex items-center justify-center">
                <button 
                        type="button"  onClick={() =>SearchExpense()} 
                        class="ml-2
                        bg-blue-500
                        text-white px-4
                        py-2 rounded-md
                        hover:bg-indigo-600
                        focus:outline-none focus:ring
                        focus:ring-indigo-200
                        focus:ring-opacity-50 uppercase">Submit</button>

                     
                </div>
            </div>

        </form>
        { isCompletesubmit?   <div class="max-w-3xl  mt-10 mx-auto flex items-center justify-center">
          <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
              <span class="sr-only">Loading...</span>
          </div> 
       </div>   : ''
      }
      <div className="max-w-screen-lg mx-auto mt-10 h-screen">
          
      <PDFViewer className="w-full h-full">
      <Document>
      <Page size={{ width: 1000, height: 1000 }} style={styles.container}>
   
      <View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', width: '80%', marginLeft: 0 }}>
                <View style={{ width: '40%' }}>
                <Text style={{ fontSize: 9 }}>TIN: {Tin} </Text>
                  <Text style={{ fontSize: 9 }}>OWNER'S NAME: {OwnerName}</Text>
                  <Text style={{ fontSize: 9 }}>OWNER'S TRADE NAME: {Trade_name}</Text>
                  <Text style={{ fontSize: 9 , marginBottom: 24 }}>OWNER'S ADDRESS:{Reg_address}</Text>
                </View>
                <View style={{ width: '50%' }}>
                  <Text  style={{ fontSize: 24, color: 'blue',textAlign: 'center' }}>Expense Disapproved</Text>
                  <Text> {ConvertDate(fromdate)} - {ConvertDate(todate)}</Text>
                  <Text style={{ marginBottom: 24 }}></Text>
                </View>
              </View>


         
          {pendinglist.map((Rpt) => ( 
            <>
              <View style={styles.imageContainer}>
              <Image
              src={Rpt.image} // Provide the path or URL to your image
              style={{ width: '500', height: '500', maxWidth: '100%', maxHeight: '100%' }} />
              </View>
          


            <View style={styles.row}>
            <View style={styles.label}>
            <Text>Date Encoded:</Text>
            <Text>Series:</Text>
         
            <Text>Date Transaction:</Text>

            <Text>Seller:</Text>
            <Text>Address:</Text>
            <Text>Tin:</Text>
            <Text>Encoder:</Text>
            <Text>Invoice No:</Text>
            <Text>Classification:</Text>

            </View>
            <View style={styles.value}>
              <Text>{Rpt.created_at}</Text> 
              <Text>{Rpt.series.toLowerCase()}</Text> 
              <Text>{Rpt.date_trans}</Text> 
  

              <Text>{Rpt.seller.toLowerCase()}</Text> 
              <Text>{Rpt.address.toLowerCase()}</Text> 

              <Text>{Rpt.tin}</Text>
              <Text>{Rpt.encoder}</Text>
              <Text>{Rpt.ornum}</Text>
              <Text>{Rpt.classification}</Text>
            </View>
            </View>
            <View  style={styles.row}>
       
            
              <Text style={styles.cell}>Particular</Text>
              <Text style={styles.cell}>Quantity</Text>
              <Text style={styles.cell}>Price</Text>
              <Text style={styles.cell}>Total</Text>
              <Text style={styles.cell}>Vatable</Text>
            </View>
            {Rpt.expense_item.map((item) => ( 
               <>
               
               <View  style={styles.row}>
              
              <Text style={styles.cell}>{item.particular.toLowerCase()}</Text>
              <Text style={styles.cell}>{item.qty}</Text>
              <Text style={styles.cell}>{formatAmountDisplay(item.price)}</Text>
              <Text style={styles.cell}>{formatAmountDisplay(item.price*item.qty)}</Text>
              <Text style={styles.cell}>{item.vat_class}</Text>
            </View>
         
                </>
               ))}
      
      
            {/* <View style={styles.row}>
            <View style={styles.label}>
            <Text>Vat Sales:</Text>
            <Text>Vat Amount:</Text>
            <Text>Vat Zero Rated:</Text>
            <Text>Vat Exempt:</Text>
            <Text>Total Amount Due:</Text>
            </View>
            <View style={styles.value}>
              <Text>{Rpt.vat_sales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text> 
              <Text>{Rpt.vat_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text> 
              <Text>{Rpt.vat_zero_rated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
              <Text>{Rpt.vat_exempt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
              <Text>{Rpt.total_amount_due.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
            </View>
            </View>
            <View style={{ padding: 10 }}>
            <Text style={{ fontSize: 24 }}>Remarks: {Rpt.reason}</Text>
            </View> */}
          <Text style={{ fontSize: 24 }}>Remarks: {Rpt.reason}</Text>


            </>
            ))}
              
              <View style={styles.row}>
          <Text style={{  marginTop: 10  }}>Username: {username} </Text>
          <Text style={{  marginTop: 10  }}> </Text>
          <Text style={{  marginTop: 10  }}> </Text>
          <Text style={{ marginTop: 10  }}>Date Print: {manilaDate} </Text>
      </View>
   
          </View>
        </Page>
      </Document>
      </PDFViewer>


      </div>

      
       
    


       
          
      </div>);

}
export default RptDisapprove;