import { Fragment, useContext,useState,useEffect } from 'react'

import TransitionComponent from '../../../components/navigation/transition.component'
import SidebarNavigation from '../../../components/navigation/navigation.component'
import HeaderComponent from '../../../components/navigation/header.component'
import RptPurchaseTransaction from '../../../layout/report/rptpurchasetransaction.layout'

const RptPurchaseTransactionPage =() =>{
  const [sidebarOpen, setSidebarOpen] = useState(false)
    return(
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto  px-6 pb-4">
            <div className="flex h-42 shrink-0 items-center mt-10">
              <img
                 className="h-42 w-auto mx-auto"
               src={process.env.PUBLIC_URL + '/logoIcon.png'}
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'Reports'} subactivepage={'Summary list of purchase'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
              <div className="space-y-10 divide-y divide-gray-900/10">
                  <RptPurchaseTransaction/>
                </div>
             </div>
            </div>
          </main>
        </div>
      </div>
  </>);

}
export default RptPurchaseTransactionPage;