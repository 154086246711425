

import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon,PencilSquareIcon, TrashIcon  } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'
import { UserContext } from '../../../contexts/user.context';
import { formatAmountDisplay,formatAmount } from '../../../utils/ValidationField';
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import 'select2/dist/css/select2.min.css';
import $ from 'jquery';
import 'select2';
import { useNavigate } from 'react-router-dom'
const JournalAdjustment =() =>{
  
    const navigate = useNavigate();
    
    const [DebitList, setDebitList] = useState([]);

    const [CreditList, setCreditList] = useState([]);

    const { company_id,user_id } = useContext(UserContext);

    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)

    const [SelectAccountList, setSelectAccountList] = useState([]);

    const defaultFormFields = {
        CreditAccount:'',
        CreditAmount:'',
        DebitAccount:'',
        DebitAmount:'',
        ModalAccount:'',
        ModalAmount:'',
        ModeModal:'Debit',
        ModalIndex:'',
        Remarks: '',
        DateAdjustment:''
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { CreditAccount,CreditAmount,DebitAccount,DebitAmount,ModalAccount,ModalAmount,Remarks,DateAdjustment} = formFields;


      const DeleteDebitItem = (index) => {

        
        setDebitList((prevList) =>
          prevList.filter((_, i) => i !== index)
        );
      };


      
      const DeleteCreditItem = (index) => {

        setCreditList((prevList) =>
          prevList.filter((_, i) => i !== index)
        );
      };


      const OpenModal = (Account,Amount,ModeModal,ModalIndex) => {

        formFields.ModalAccount=Account;
        formFields.ModalAmount=Amount;
        formFields.ModeModal=ModeModal;
        formFields.ModalIndex=ModalIndex;
        setOpen(true);
        
      };

      const UpdateModalItem =() =>{
        
        if(formFields.ModeModal=='Debit'){
         
                UpdateDebitItem(formFields.ModalIndex, { 
                    debit_account: formFields.ModalAccount, 
                    debit_amount: formFields.ModalAmount 
                });

        }else{
  
            UpdateCreditItem(formFields.ModalIndex, { 
                credit_account: formFields.ModalAccount, 
                credit_amount: formFields.ModalAmount 
            });
        }

        setOpen(false);
      }

      const UpdateDebitItem = (index, updatedItem) => {
        setDebitList((prevList) =>
            prevList.map((item, i) => (i === index ? updatedItem : item))
            );
        };
        
     
        const UpdateCreditItem = (index, updatedItem) => {
            setCreditList((prevList) =>
                prevList.map((item, i) => (i === index ? updatedItem : item))
            );
        };
        const mergedList = () => {
            const maxLength = Math.max(DebitList.length, CreditList.length);
            const result = [];
    
            for (let i = 0; i < maxLength; i++) {
                if (i < DebitList.length) {
                    result.push(DebitList[i]);
                }
                if (i < CreditList.length) {
                    result.push(CreditList[i]);
                }
            }
    
            return result;
        };
    

        const preventChange = useRef(false); // Flag to prevent recursion
      
    useEffect(() => {

        LoadAccount();
      
        
      }, []);
      const handleChange = (event) => {
        const { name, value } = event.target;

        if (preventChange.current) return; // Prevent recursion

        // Clear the other select element based on which one changed
        if (name === 'DebitAccount') {
            preventChange.current = true; // Set flag to prevent recursion
            $(selectRefCredit.current).val(null).trigger('change'); // Clear Select2
            preventChange.current = false; // Reset flag
        }
        if (name === 'CreditAccount') {
            preventChange.current = true; // Set flag to prevent recursion
            $(selectRefDebit.current).val(null).trigger('change'); // Clear Select2
            preventChange.current = false; // Reset flag
        }
      const formattedValue =
      name === 'CreditAmount' ? formatAmount(value) :
      name === 'DebitAmount' ? formatAmount(value) :
      name === 'ModalAmount' ? formatAmount(value):
      name === 'TxtPrice' ? formatAmount(value) :value;
  
        setFormFields({ ...formFields, [name]: formattedValue });

      };
      const selectRefDebit = useRef(null);
      const selectRefCredit= useRef(null);
      const selectRefModal= useRef(null);
      
      useEffect(() => {
        // Initialize Select2
        $(selectRefDebit.current).select2({
          placeholder: "--Account Title--",
          allowClear: true,
        });
    
        // Handle change event
        $(selectRefDebit.current).on('change', handleChange);

        $(selectRefCredit.current).select2({
            placeholder: "--Account Title--",
            allowClear: true,
          });
      
          // Handle change event
          $(selectRefCredit.current).on('change', handleChange);
      

          $(selectRefModal.current).select2({
            placeholder: "--Account Title--",
            allowClear: true,
          });
      
          // Handle change event
          $(selectRefModal.current).on('change', handleChange);

          return () => {
            $(selectRefDebit.current).off('change', handleChange);
            $(selectRefDebit.current).select2('destroy');

            $(selectRefCredit.current).off('change', handleChange);
            $(selectRefCredit.current).select2('destroy');

            $(selectRefModal.current).off('change', handleChange);
            $(selectRefModal.current).select2('destroy');

          };
      }, []);






      const clearSelection = () => {
        formFields.debit_account='';
        $(selectRefDebit.current).val(null).trigger('change'); // Clear Select2
        $(selectRefCredit.current).val(null).trigger('change'); // Clear Select2

        
      };

        const resetFormFields = () => {
        setFormFields(defaultFormFields);
      };
    const LoadAccount = async () => {



        const postData = {
          "company_id": company_id,
        };
       
        
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/setting/account/list', requestOptions)
        .then( async response => 
          {
          
            // Check the status code
            if (response.status === 422) {

               
            }else{
              const data = await response.json();
              
              setSelectAccountList(data.data); 

              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
        //   setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        // setLoad(true);
    } 


      const AddDebit = () => {


        if(DebitAccount=='' || parseFloat(DebitAmount.replace(/,/g, ''))<0  || DebitAmount =='' ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Please complete the data',
              
              })

              return;
          }
        const newItem = { 
        "debit_account": DebitAccount ,
        "debit_amount": DebitAmount,
       }; // Example new item
       setDebitList(currentItems => [...currentItems, newItem]);

       clearSelection();
       resetFormFields();

      };

      const AddCredit = () => {


        if(CreditAccount=='' || parseFloat(CreditAmount.replace(/,/g, ''))<0  || CreditAmount ==''){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Please complete the data',
              
              })

              return;
          }
        const newItem = { 
        "credit_account": CreditAccount ,
        "credit_amount": CreditAmount,
       }; // Example new item
       setCreditList(currentItems => [...currentItems, newItem]);

        
       clearSelection();
       resetFormFields();

      };


      const totalAmountDebit = DebitList.reduce((accumulator, item) => {
        const amount = parseFloat(item.debit_amount.replace(/,/g, '')); // Remove commas and convert to float
        return accumulator + amount; // Sum the amounts
    }, 0);
  

    const totalAmountCredit = CreditList.reduce((accumulator, item) => {
        const amount = parseFloat(item.credit_amount.replace(/,/g, '')); // Remove commas and convert to float
        return accumulator + amount; // Sum the amounts
    }, 0);

    const manilaDate = new Date().toLocaleDateString('en-CA', {
        timeZone: 'Asia/Manila',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    const SaveJournal = () => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })

          if(Remarks =='' || DebitList.length==0 || CreditList.length==0 || DateAdjustment ==''){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Complete The Data',
                
                })
            return;
        }
        if(totalAmountDebit != totalAmountCredit ){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Not Balance',
                
                })
            return;
        }


        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "Do you want to save",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel',
            reverseButtons: true
          }).then(async (result) => {
            if (result.isConfirmed) {
      
              const postData = {
                "company_id": company_id,
                "user_id": user_id,
                "entry_item": mergedList(),
                "remark": manilaDate+'-'+Remarks,
                "date": DateAdjustment,
                 "type": 'AE'
              };
              

              console.log(postData);
        
              const requestOptions = {
                method: 'POST',
                headers: 
                { 'Content-Type': 'application/json',
                'Accept': 'application/json', 
                 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
                body: JSON.stringify(postData)
              };
      
              await fetch(process.env.REACT_APP_API_URL+'api/journal/entry', requestOptions)
              .then( async response => 
                {
                    console.log(response);
                  // Check the status code
                  if (response.status === 422) {
                    // API call succeeded
      
                    const data = await response.json();
                
                    // setCompletesubmit(false);
      
                  
                  }else{
                    const data = await response.json();
                    console.log(data.message);  
                    // setCompletesubmit(false);
                    
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Journal Entry successfully Save',
                            showConfirmButton: false,
                            timer: 1500
                        })  
                         navigate('/journal/list');
        
                    
                  }
                }
                
              )
              .then(data => {
                // Handle the response
                console.log(data);
              })
              .catch(error => {
                // Handle any errors
                console.error(error);
              });
      
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
      
            }
          })
         

    };
    return (
        <div>
        <div className="sm:flex sm:items-center mt-4 mb-5">
            <div className="sm:flex-auto">
                <h1 className="text-2xl font-semibold leading-6 text-gray-900 capitalize ">Journal Adjustment Entry</h1>
        
            </div>

        </div>
        <div className= 'mt-10 flow-root'>
        {DebitList.map((item, index) => (
            <>
            
                <div className='flex gap-4 mb-4'>
                    <div className=''>
                        <label htmlFor="username" className="block text-sm font-bold leading-6 text-gray-900">
                            Debit
                        </label>
                    </div>
                    <div className='w-3/4'>
                    <div className='flex gap-24'>
                        <div className='w-1/4'>
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset  focus-within:ring-indigo-600 sm:max-w-md">
                        
                                <input
                                    required
                                    value={item.debit_account}
                                    type="text"
                                    readOnly
                                    placeholder='Account Title'
                                    className="px-4 block flex-1 -0 bg-transparent py-1.5 text-gray-900  sm:text-sm sm:leading-6 "
                                />
                            </div>
                        </div>
                        <div >
                            <div className="flex items-center space-x-2">
                                <input
                                    required
                                    type="text"
                                    value={item.debit_amount}
                                    readOnly
                                    placeholder=' Amount'
                                    className="flex-1 bg-transparent py-1.5 pl-4 text-gray-900 sm:text-sm sm:leading-6 border-blue-500 border text-right pr-2"
                                />
                               
                               <span>
                               <button
                                     onClick={() => OpenModal(item.debit_account,item.debit_amount,'Debit',index)}
                                    type="button"
                                    className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    <PencilSquareIcon aria-hidden="true" className="h-5 w-5" />
                                </button>
                                <button
                                 
                                    type="button" 
                                    onClick={() => DeleteDebitItem(index)}
                                    className="rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    <TrashIcon aria-hidden="true" className="h-5 w-5" />
                                </button>
                               
                            </span>
                            </div>

                        </div>
                    </div>
                    </div>
            </div>
            </>
          ))}
          <div className='flex gap-4'>
                <div className=''>
                    <label htmlFor="username" className="block text-sm font-bold leading-6 text-gray-900">
                        Debit
                    </label>
                </div>
                <div className='w-3/4'>
                <div className='flex gap-24'>
                    <div className='w-1/4'>
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset  focus-within:ring-indigo-600 sm:max-w-md">
                    

                        <select
                            ref={selectRefDebit}
                            className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 
                            shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                            focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                            name="DebitAccount"
                            value={DebitAccount}
                            required
                            >
                            <option value=""></option>
                            {SelectAccountList.map((Class) => (
                                <option key={Class.id} value={Class.account}>
                                {Class.account}
                                </option>
                            ))}
                            </select>
                            
                        </div>
                    </div>
                    <div >
                        <div className="flex items-center space-x-2">
                            <input
                               id="DebitAmount"
                               name="DebitAmount"
                               value={DebitAmount}
                               required
                               onChange={handleChange}
                                type="text"
                                placeholder=' Amount'
                                className="flex-1 bg-transparent py-1.5 pl-4 text-gray-900 sm:text-sm sm:leading-6 border-blue-500 border text-right pr-2"
                            />
                            <span>
                                <button
                                 
                                    type="button" onClick={AddDebit}
                                    className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusIcon aria-hidden="true" className="h-5 w-5" />
                                </button>
                            </span>
                        </div>

                    </div>
                </div>
                </div>
          </div>
         
        </div>

        <div className= 'mt-10 flow-root'>
        {CreditList.map((item, index) => (
            <>
            
                <div className='flex gap-4 mb-4'>
                    <div className=''>
                        <label htmlFor="username" className="block text-sm font-bold leading-6 text-gray-900">
                            Credit
                        </label>
                    </div>
                    <div className='w-3/4 ml-12'>
                    <div className='flex gap-24'>
                        <div className='w-1/4'>
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset  focus-within:ring-indigo-600 sm:max-w-md">
                        
                                <input
                                    required
                                    value={item.credit_account}
                                    type="text"
                                    readOnly
                                    placeholder='Account Title'
                                    className="px-4 block flex-1 -0 bg-transparent py-1.5 text-gray-900  sm:text-sm sm:leading-6 "
                                />
                            </div>
                        </div>
                        <div >
                            <div className="flex items-center space-x-2">
                                <input
                                    required
                                    type="text"
                                    value={item.credit_amount}
                                    readOnly
                                    placeholder=' Amount'
                                    className="flex-1 bg-transparent py-1.5 pl-4 text-gray-900 sm:text-sm sm:leading-6 border-blue-500 border text-right pr-2"
                                />
                               
                               <span>
                               <button
                                     onClick={() => OpenModal(item.credit_account,item.credit_amount,'Credit',index)}
                                    type="button"
                                    className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    <PencilSquareIcon aria-hidden="true" className="h-5 w-5" />
                                </button>
                                <button
                                    onClick={() => DeleteCreditItem(index)}
                                    type="button" 
                                    className="rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    <TrashIcon aria-hidden="true" className="h-5 w-5" />
                                </button>
                            </span>
                            </div>

                        </div>
                    </div>
                    </div>
            </div>
            </>
          ))}
          </div>
        <div className= 'mt-2 flow-root'>

        <div className='flex gap-4 '>
                <div className=''>
                    <label htmlFor="Credit" className="block text-sm font-bold leading-6 text-gray-900">
                        Credit
                    </label>
                </div>
                <div className='w-3/4 ml-12'>
                <div className='flex gap-24'>
                    <div className='w-1/4'>
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset  focus-within:ring-indigo-600 sm:max-w-md">
    
                         <select
                            ref={selectRefCredit}
                            className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 
                            shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                            focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            name="CreditAccount"
                            value={CreditAccount}
                            required
                            >
                            <option value=""></option>
                            {SelectAccountList.map((Class) => (
                                <option key={Class.id} value={Class.account}>
                                {Class.account}
                                </option>
                            ))}
                            </select>
                        </div>
                    </div>
                    <div >
                        <div className="flex items-center space-x-2">
                        <input
                               id="CreditAmount"
                               name="CreditAmount"
                               value={CreditAmount}
                               required
                               onChange={handleChange}
                                type="text"
                                placeholder='Amount'
                                className="flex-1 bg-transparent py-1.5 pl-4 text-gray-900 sm:text-sm sm:leading-6 border-blue-500 border text-right pr-2"
                            />
                            <span>
                                <button
                                 
                                    type="button" onClick={AddCredit}
                                    className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusIcon aria-hidden="true" className="h-5 w-5" />
                                </button>
                            </span>
                        </div>

                    </div>
                </div>
                </div>
          </div>
        </div>

        <div className='flex gap-4 mt-32'>
            <div className='border border-black w-96 p-4'>
                <div className='flex gap-12'>
                    <div className='w-11'>
                        <label htmlFor="Debit" className="block  font-bold leading-6 text-gray-900 text-2xl">
                            Debit:
                        </label>
                    </div>
                    <div className='w-1/2 text-2xl'>
                         {formatAmountDisplay(totalAmountDebit)}
                    </div>
                </div>
            </div>
            <div className='border border-black w-96 p-4'>
                <div className='flex gap-12'>
                    <div className='w-11'>
                        <label htmlFor="Debit" className="block  font-bold leading-6 text-gray-900 text-2xl">
                            Credit:
                        </label>
                    </div>
                    <div className='w-1/2 text-2xl'>
                         {formatAmountDisplay(totalAmountCredit)}
                    </div>
                </div>
            </div>
        </div>

    <div className='flex'>
     
        <div className='mt-4 w-3/6	'>
            <label htmlFor="comment" className="block  font-medium leading-6 text-gray-900 text-xl">
                Remarks
            </label>
            <div className="mt-2">
            <div className="flex items-center">
                    <span className="flex select-none items-center pl-3 text-gray-500 text-xl font-bold pr-2">
                {manilaDate}</span>
                    <textarea
                        name="Remarks"
                        value={Remarks}
                        required
                        onChange={handleChange}
                        rows={2}
                        className="w-3/6 pl-2 block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                    />
            </div>
            </div>
        </div>
   
    </div>
    <div className='flex'>
    <div className='mt-4'>
        <label htmlFor="comment" className="block  font-medium leading-6 text-gray-900 text-xl">
            Adjustment Date
        </label>
        <div className="mt-2">
            <div className="flex items-center">
                    <input
                    type="date"
                    name="DateAdjustment"
                    id="DateAdjustment"
                    value={DateAdjustment}
                    onChange={handleChange}
                    className="pl-2 block w-60	 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
            </div>
        </div>

        </div>
    </div>

    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
        <button
        type="submit"
        onClick={() => SaveJournal()}
        className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
        Save
        </button>
    </div>
        <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                         <h1 class="text-base font-semibold leading-6 text-gray-900 text-left " >Update {formFields.ModeModal} Item</h1>
                                        <div className="mt-2">
                                      

                                        <div className="grid grid-cols-1">
                                          
                                            <div  className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 ">
                                                <div className="px-4 py-6 sm:p-8">
                                                    <div className="grid grid-cols-1 ">
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                                Account Name
                                                            </label>
                                                            <div className="mt-2">

                                                            <select
                                                                ref={selectRefModal}
                                                                className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 
                                                                shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                                                                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                                                                name="ModalAccount"
                                                                value={ModalAccount}
                                                                onChange={handleChange}
                                                                required
                                                                >
                                                                <option value=""></option>
                                                                {SelectAccountList.map((Class) => (
                                                                    <option key={Class.id} value={Class.account}>
                                                                    {Class.account}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                      
                                                            </div>

                                                            
                                                        </div>

                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                                Amount
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                type="text"
                                                                id="ModalAmount"
                                                                name="ModalAmount"
                                                                value={ModalAmount}
                                                                required
                                                                onChange={handleChange}
                                                                className=" text-right p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>

                                                        </div>
                                                    </div>
                                            
                                                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
                                                    <button
                                                    type="submit"
                                                    onClick={() => UpdateModalItem()}
                                                    className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                    Save
                                                    </button>
                                                </div>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>

      </div>);

}
export default JournalAdjustment;