 
import { ExclamationTriangleIcon, EyeDropperIcon, EyeIcon } from '@heroicons/react/20/solid'
import { UserContext } from '../../contexts/user.context'
// import { useEffect,useState,useContext } from 'react'
import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import PaginatedTable from '../../components/pagination/paginatedtable.component'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon,PencilSquareIcon  } from '@heroicons/react/24/outline'
import { formatAmountDisplay } from '../../utils/ValidationField'
import { Document, Page, Text, View, PDFViewer,Image,StyleSheet,Link   } from '@react-pdf/renderer';
import React from 'react';



const BalanceSheetLayout =() =>{

  const styles = {
    container: {
        padding: 10,
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: 'gray',
        paddingBottom: 0,
    },
    label: {
        width: 100,
        fontWeight: 'bold',
        fontSize: 14,
    },
    centeredRow: {
        justifyContent: 'center', // Center items in this specific row
      
        fontWeight: 'bold',
        padding: 2,
        marginTop: 10, // Adjust this value as needed for spacing
    },
    value: {
        flex: 1,
        fontSize: 14,
    },
    imageContainer: {
        marginBottom: 8,
        alignItems: 'center',
    },
    rowExpense: {
        flexDirection: 'row',
    },
    boldText: {
        fontSize: 10,
        fontWeight: 'bold', // Makes the text bold
        padding: 2,
        border: '1px solid black',

      },
    cell: {
        width: '20%',
        padding: 2,
        border: '1px solid black',
        fontSize: 8, // Add font size here
        textTransform: 'capitalize', // Capitalize text
        wordWrap: 'break-word', // Add wordWrap property here
    },
};

useEffect( () => {
  
    SubmitBalanceSheet();
 
}, []); 
const manilaDate = new Date().toLocaleDateString('en-US', {
    timeZone: 'Asia/Manila',
    year: 'numeric',        // Full numeric year (e.g., 2017)
    month: 'long',          // Full month name (e.g., December)
    day: '2-digit',         // Day in two digits (e.g., 31)
  });
  

  const manilaDateNumeric = new Date().toLocaleDateString('en-CA', {
    timeZone: 'Asia/Manila',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

const [isCompletesubmit, setCompletesubmit] = useState(false);

const currentYear = new Date().getFullYear();
  
// Generate an array of years (e.g., 10 years before and 10 years after the current year)
const yearOptions = [];
for (let year = currentYear - 10; year <= currentYear + 10; year++) {
  yearOptions.push(year);
}

const [selectedYear, setSelectedYear] = useState(currentYear);

const handleChange = (event) => {
  setSelectedYear(event.target.value);
};
const { company_id,Tin ,Trade_name ,Reg_address,OwnerName,username} = useContext(UserContext);


const [InitialBalanceSheet, setInitialBalanceSheet] = useState([]);



const SubmitBalanceSheet = async () => {
    
    setCompletesubmit(true);

  
      const postData = {
        "company_id": company_id,
        "year":selectedYear
      };

      console.log(postData);
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/fs/report/balance/sheet', requestOptions)
      .then( async response => 
        {
          console.log(response);
           
          // Check the status code
          if (response.status === 422) {
       
             setCompletesubmit(false);
          }else{
            const data = await response.json();

            console.log(data.data);
          
            setCompletesubmit(false);
            setInitialBalanceSheet(data.data)

          
    
       
            
              
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
        
      })
      .catch(error => {
        // Handle any errors
     
      });
    

      setCompletesubmit(false);
  } 

  
// const [YearSelected, setYearSelected] = useState("");
    
    return (
        <div>
        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Balance Sheet</h1>
           
          </div>
   

        </div>

   
        <form >
            <div class="max-w-lg bg-gray-50 p-6 rounded-lg shadow-md mt-10 mx-auto">
                <div class="grid grid-cols-12">
                  <div class="col-span-12">
                    <div class="flex items-center">
                        <div className='w-1/4'>
                            <label for="YearSelected" class="text-sm font-medium text-gray-700 mr-4">Year</label>
                        </div>
                        <div  className='w-7/12	'>
                            <select
                                id="YearSelected"
                                name="YearSelected"
                                value={selectedYear}
                                onChange={handleChange}
                                className="w-full p-2 border rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                required
                            >
                                {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                                ))}
                            </select>
                        </div>
                      </div>
                  </div> 
            

                </div>
                <div class="mt-4 flex items-center justify-center">
                <button 
                        type="button" 
                       onClick={() =>SubmitBalanceSheet()} 
                        class="ml-2
                        bg-blue-500
                        text-white px-4
                        py-2 rounded-md
                        hover:bg-indigo-600
                        focus:outline-none focus:ring
                        focus:ring-indigo-200
                        focus:ring-opacity-50 uppercase">Submit</button>

                     
                </div>
            </div>

        </form>
        { isCompletesubmit?   <div class="max-w-3xl  mt-10 mx-auto flex items-center justify-center">
          <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
              <span class="sr-only">Loading...</span>
          </div> 
       </div>   : ''}

       <div className="max-w-screen-lg mx-auto mt-10 h-screen">
          
       <PDFViewer className="w-full h-full">
          <Document>
            <Page size="A4" style={styles.container}>
              <View style={{ padding: 10 }}> {/* Reduced padding for better fit */}
                
                {/* Header Information */}
                <View>
                  <Text style={{ fontSize: 8 }}>TIN: {Tin}</Text>
                  <Text style={{ fontSize: 8 }}>OWNER'S NAME: {OwnerName}</Text>
                  <Text style={{ fontSize: 8 }}>OWNER'S TRADE NAME: {Trade_name}</Text>
                  <Text style={{ fontSize: 8, marginBottom: 4 }}>OWNER'S ADDRESS: {Reg_address}</Text>
                </View>


                <View>

                  <Text style={{ fontSize: 12,textAlign: 'center' }}>STATEMENT OF FINANCIAL POSITION </Text>
                  <Text style={{ fontSize: 8,textAlign: 'center', marginBottom: 4 }}>{manilaDate}</Text>
            
                </View>

                {/* Header Row */}
              

                <View style={styles.container}>
                {/* Static Row */}
                <View style={styles.row}>
                    <Text style={[styles.cell, { flex: 2, textAlign: 'center', paddingTop: 10,fontSize :10 }]}>
                    ACCOUNT TITLE
                    </Text>
                    <Text style={styles.cell}>
                    To FS{'\n'}{manilaDateNumeric}
                    </Text>
                </View>

                {/* Dynamic Rows */}
                {InitialBalanceSheet.map((Rpt,indexitem) => (
                    <>
                        <View key={Rpt.id} style={styles.row}>
                        <Text  style={[styles.boldText, { flex: 2, paddingTop: 10, fontWeight: 'bold' }]}>{Rpt.name}</Text>
                        <Text style={styles.cell}></Text>
                        </View>
                        {Rpt.fs_item.map((item, itemIndex) => (
                            <>
                             <View key={item.id} style={styles.row}>
                            <Text style={[styles.cell, { flex: 2, paddingTop: 10 ,paddingLeft: 10}]}>{item.name}</Text>
                            {((Rpt.name=='CURRENT ASSETS' && item.type=='Credit' && item.total !=0)  || (Rpt.name=='NON CURRENT ASSETS'  && item.type=='Credit'  && item.total !=0) || (Rpt.name=='EQUITY'  && item.type=='Debit' && item.total !=0) || (Rpt.name=='NON-CURRENT LIABILITIES' && item.type=='Debit' && item.total !=0) || (Rpt.name=='NON-CURRENT LIABILITIES' && item.type=='Debit' && item.total !=0))?
                              <Text style={[styles.cell , {  paddingTop: 10 }]}>{'('+formatAmountDisplay(Math.abs(item.total))+')'} </Text>
                              :
                              <Text style={[styles.cell , {  paddingTop: 10 }]}>{formatAmountDisplay(Math.abs(item.total))} </Text>
                             }
                          
                            </View>
                            </>
                        ))}
                        <View key={Rpt.id} style={styles.row}>
                        <Text  style={[styles.boldText, { flex: 2, paddingTop: 10, fontWeight: 'bold' }]}></Text>
                        {((Rpt.name=='CURRENT ASSETS' && Rpt.total <0 || Rpt.name=='NON CURRENT ASSETS' && Rpt.total <0 || Rpt.name=='CURRENT LIABILITIES' && Rpt.total >0 || Rpt.name=='NON-CURRENT LIABILITIES' && Rpt.total >0) ?
                         <Text style={styles.cell}>{'('+formatAmountDisplay(Math.abs(Rpt.total))+')'} </Text> 
                         :  
                         <Text style={styles.cell}>{formatAmountDisplay(Math.abs(Rpt.total))} </Text>
                        )}
                       
                        </View>

                        {(indexitem==1)?
                        <View key={Rpt.id} style={styles.row}>
                        <Text  style={[styles.boldText, { flex: 2, paddingTop: 10, fontWeight: 'bold'  ,paddingLeft: 10}]}>TOTAL ASSETS</Text>

                          {((parseFloat(InitialBalanceSheet[0].total)+InitialBalanceSheet[1].total)>=0)?
                          <Text style={styles.cell}>{formatAmountDisplay((parseFloat(InitialBalanceSheet[0].total)+InitialBalanceSheet[1].total))}</Text>
                          :
                          <Text style={styles.cell}>{'('+formatAmountDisplay(Math.abs((parseFloat(InitialBalanceSheet[0].total)+InitialBalanceSheet[1].total)))+')'}</Text>
                          }
                        
                
                        </View>
        
                        :''}

                        {(indexitem==3)?
                        <View key={Rpt.id} style={styles.row}>
                        <Text  style={[styles.boldText, { flex: 2, paddingTop: 10, fontWeight: 'bold'  ,paddingLeft: 10,}]}>TOTAL LIABILITIES</Text>
                            {((parseFloat(InitialBalanceSheet[2].total)+parseFloat(InitialBalanceSheet[3].total))>=0)?

                            <Text style={styles.cell}>{'('+formatAmountDisplay(parseFloat(InitialBalanceSheet[2].total)+parseFloat(InitialBalanceSheet[3].total))+')'}</Text>
                            :
                          <Text style={styles.cell}>{formatAmountDisplay(Math.abs(parseFloat(InitialBalanceSheet[2].total)+parseFloat(InitialBalanceSheet[3].total)))}</Text>
                          }
                           
                        </View>

                        :''}

                        {(indexitem==4)?
                        <View key={Rpt.id} style={styles.row}>
                        <Text  style={[styles.boldText, { flex: 2, paddingTop: 10, fontWeight: 'bold'  ,paddingLeft: 10}]}>TOTAL LIABILITIES AND EQUITY</Text>
                          <Text style={styles.cell}>  <Text style={styles.cell}>{formatAmountDisplay(Math.abs((InitialBalanceSheet[2].total+InitialBalanceSheet[3].total)+InitialBalanceSheet[4].total))}</Text></Text>
                        </View>

                        :''}

                   
                    </>
          
                ))}
                </View>


                {/* Footer Information */}
                <View style={styles.row}>
                  <Text style={{ fontSize: 8, marginTop: 10 }}>Username: {username}   </Text>
                  <Text style={{ fontSize: 8 }}></Text> {/* Placeholder to maintain layout */}
                  <Text style={{ fontSize: 8, marginTop: 10 }}>Date Print: {manilaDate}</Text>
                </View>

              </View>
            </Page>
          </Document>
        </PDFViewer>



    
    
          </div>
    
      </div>
      );

   
}
export default BalanceSheetLayout;